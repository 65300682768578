import { BASE_URL } from "../constants";
import ApiCaller from "../helpers/utils/ApiCaller";

export const getAllProducts = async (query) => {
  try {
    const response = await ApiCaller.get(
      `${BASE_URL}/products/${query}`,
      {
        shouldThrottle: true,
      }
    );
    return response;
  } catch (error) {
    console.error("Fetching products failed:", error);
    throw error;
  }
};

export const getProductById = async (productId) => {
    try {
      const response = await ApiCaller.get(
        `${BASE_URL}/products/${productId}`,
        {
          shouldThrottle: true,
        }
      );
      return response;
    } catch (error) {
      console.error("Fetching product failed:", error);
      throw error;
    }
  };

