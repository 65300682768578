import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { handleAuth, LOGIN } from "../thunks/authThunk";
import { LOGOUT } from "../actions/authActions";
import { AuthState } from "../types";
import { updateCookie } from "../../helpers/utils/Cookies";
import { TOKEN_COOKIE_NAME } from "../../constants";

const initialState: AuthState = {
  email: null,
  isLoggedIn: false,
  status: "idle",
  error: null,
  accessToken: null,
  refreshToken: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginSuccess: (
      state,
      action: PayloadAction<{
        email: string;
        accessToken: string;
        refreshToken: string;
      }>
    ) => {
      state.email = action.payload.email;
      state.isLoggedIn = true;
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      updateCookie(TOKEN_COOKIE_NAME, action.payload.accessToken, 60);
    },
    logout: (state) => {
      state.email = null;
      state.isLoggedIn = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(handleAuth.pending, (state) => {
        state.status = "loading";
      })
      .addCase(handleAuth.fulfilled, (state, action) => {
        state.status = "succeeded";
        if (action.payload.actionType === LOGIN) {
          state.email = action.payload.username;
          state.isLoggedIn = true;
        } else if (action.payload.actionType === LOGOUT) {
          state.email = null;
          state.isLoggedIn = false;
        }
      })
      .addCase(handleAuth.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { loginSuccess, logout } = authSlice.actions;

export default authSlice.reducer;
