import styled from "styled-components";

export const SavedPayments = styled.div`
  .saved-payment {
    /* width: 1060px; */
    /* height: 680px; */
    /* flex-shrink: 0; */
    border-radius: 10px;
    background: var(--white, #fff);

    padding: 20px;
  }
   .header {
    color: #011D29;
    font-family: Reckless Neue;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 111.111% */
    margin-bottom: 16px;
    padding:20px;
    padding-bottom:0;
  }

  .icon-container {
    /* width: 460px; */
    /* height: 204px; */
    /* flex-shrink: 0; */
    width: 45%;
    margin-right: 2.5rem;
    margin-bottom: 20px;
    border-radius: 8px;
    border: 2px dashed var(--Dark-grey, #dbe0e2);
    background: var(--white, #fff);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .img_txt {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 52px 0;
  }
  /* .icon-container img {
    margin-top: 50px;
  } */

  .icon-container span {
    color: var(--Black, #000);
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    opacity: 0.5;
  }

  .innerWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .card-item {
    /* width: 460px; */
    /* height: 204px; */
    /* flex-shrink: 0; */
    width: 45%;
    margin-right: 2.5rem;
    margin-bottom: 2.5rem;
    border-radius: 8px;
    border: 1px solid var(--Dark-grey, #dbe0e2);
    background: var(--white, #fff);
    padding: 30px 30px 30px 31px;
    margin-bottom: 20px;
  }

  .buttons {
    display: flex;
  }
  #bank-name {
    margin-bottom: 10px;
    color: #011d29;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  #cardNumber {
    margin-bottom: 51px;
    color: #011D29;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: 30px; /* 150% */
  }

  #cardNumber {
    display: flex;

    color: #011d29;

    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    opacity: 0.6;
  }
  .bankNameAndIcon {
    display: flex;
    justify-content: space-between;
  }
  #bankCardNumber {
    max-width: fit-content;
  }

  #editButton {
    color: var(--golden, #f1ad55);
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
  }

  #separator {
    width: 1.5px;
    height: 14.49px;
    opacity: 0.3;
    background: #000;
    transform: translateY(30%);
    margin: 0 15px;
  }
  #separator1 {
    width: 1.5px;
    height: 16px;
    flex-shrink: 0;
    border-radius: 10px;
    opacity: 0.3;
    background: #000;
    transform: translateY(7px);
    margin-left: 21px;
    margin-right: 21px;
  }
  #removeButton {
    color: var(--Black, #000);
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    opacity: 0.5;
  }

  button {
    background: none;
    border: none;
  }

  @media screen and (max-width: 1500px) {
    .card-item {
      margin-right: 0rem;
    }

    .icon-container {
      margin-right: 0rem;
    }
  }

  @media screen and (max-width: 1230px) {
    .card-item {
      padding: 1rem;
    }
    .headeprofileEdit_logo {
      margin-bottom: 6px;
    }

    #bankCardNumber {
      max-width: unset;
      // font-size: 1rem;
    }
  }

  @media screen and (max-width: 980px) {
    .card-item,
    .icon-container {
      width: 100%;
    }
    /* 
    .addresswrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    } */
  }
`;
