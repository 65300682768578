import React, { Suspense } from "react";
import { Routes, Route } from "react-router";
import { PrivateRoutes } from "./privateRoutes";
import { PublicRoutes } from "./publicRoutes";
import AuthRoute from "./AuthRoute";
import { useSelector } from "react-redux";
import { RootState } from "../store/types";
import { DEVICES } from "../constants";
import Loader from "../context/LoaderContext/Loader";

export default function AppRouter() {
  const { device } = useSelector((state: RootState) => state.app);
  return (
    <div
      style={{ marginTop: device === DEVICES.DESKTOP ? "80px" : "70px" }}
      className="app-router"
    >
      {" "}
      <Suspense fallback={<Loader />}>
        <Routes>
          {PublicRoutes.map((route) => (
            <Route path={route.path} element={<route.element />} />
          ))}
          {PrivateRoutes.map((route) => (
            <Route
              path={route.path}
              element={
                <AuthRoute>
                  <route.element />
                </AuthRoute>
              }
            />
          ))}
          <Route path="/*" element={<AuthRoute></AuthRoute>} />
        </Routes>
      </Suspense>
    </div>
  );
}
