import { useEffect, useRef, useState, useCallback } from "react";

function useOutsideClick<T extends HTMLElement>(): [
  React.RefObject<T>,
  boolean
] {
  const [isElementClicked, setElementClicked] = useState(false);
  const ref = useRef<T>(null);

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setElementClicked(true);
    } else {
      setElementClicked(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  return [ref, isElementClicked];
}

export default useOutsideClick;
