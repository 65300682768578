import styled from "styled-components";

export const Foot = styled.footer`
  * {
    width: initial;
  }
  .footer {
    background: var(--Dark-Blue, #011d29);
    margin-top: 40px;
    color: white;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 80px;
    justify-content: center;
    align-items: flex-start;
    .div1 {
      text-align: center;
    }
  }

  .FImg {
    width: 162px;
    height: 62px;
    margin-top: 40px;
  }

  .div1Para {
    width: 100%;
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    /* margin-top: 30px;
    margin-left: auto; */
    margin: auto;
    margin-top: 20px;
    padding: 5px;
  }

  .div2 {
    /* margin-top: 30px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .div2head {
    color: #fff;
    font-family: "Playfair Display", serif;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 54px;
    letter-spacing: 0.48px;
    text-transform: uppercase;
  }

  .lists2 {
    margin-top: 10px;
    list-style: none;
    display: flex;
    flex-direction: column;
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 54px;
    letter-spacing: 0.36px;
    text-transform: uppercase;
  }

  .div3 {
    /* margin-top: 30px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .div3head {
    color: #fff;
    font-family: "Playfair Display", serif;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 54px;
    letter-spacing: 0.48px;
    text-transform: uppercase;
  }

  .lists3 {
    margin-top: 10px;
    list-style: none;
    display: flex;
    flex-direction: column;
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 54px;
    letter-spacing: 0.36px;
    text-transform: uppercase;
  }

  .div4head {
    /* margin-top: 30px; */
    color: #fff;
    text-align: center;
    font-family: "Playfair Display", serif;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 54px;
    letter-spacing: 0.48px;
    text-transform: uppercase;
  }

  .lists4 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
  }

  .lists4 li {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
  }

  .follow {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .followhead {
    color: #fff;
    font-family: "Playfair Display", serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 54px;
    letter-spacing: 0.48px;
    text-transform: uppercase;
    margin-top: 40px;
    margin-left: 2rem;
  }

  .icons {
    display: flex;
    list-style: none;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 40px;
    gap: 8px;
  }

  .icons li {
    width: 35px;
    text-align: center;
    height: 35px;
    background: #f1ad55;
    border-radius: 50%;
  }

  .icons li img {
    width: 10px;
    height: 15px;
    color: #fff;
    margin-top: 9px;
  }

  .copy {
    background: #000d12;
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.32px;
    height: 80px;
    padding: 19px;
  }
`;
