import styled from "styled-components";

export const Foot = styled.footer`
  background: #011d29;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  width: 100%;

  .maindiv {
    padding: 80px;
    display: flex;
    width: 100%;
    justify-content: space-evenly;
  }

  .div1 {
  }

  .FImg {
    width: 192px;
    height: 74px;
  }

  .div1Para {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
  }

  .div2 {
    margin-top: 30px;
  }

  .div2head {
    font-family: "Playfair Display", serif;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0.02em;
    text-align: left;
    color: #ffffff;
  }

  .lists2 {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    gap: 25px;
    color: #ffffff;
    cursor: pointer;
    margin-top: 15px;
  }

  .div3 {
    margin-top: 30px;
  }

  .div3head {
    font-family: "Playfair Display", serif;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0.02em;
    text-align: left;
    color: #ffffff;
  }

  .lists3 {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    gap: 25px;
    color: #ffffff;
    cursor: pointer;
    margin-top: 15px;
  }

  .div4 {
    margin-top: 30px;
  }

  .div4head {
    font-family: "Playfair Display", serif;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0.02em;
    color: #ffffff;
  }

  .lists4 {
    list-style: none;
    display: flex;
    flex-direction: column;
    height: 5rem;
    justify-content: space-around;
    text-align: left;
    gap: 10px;
    cursor: pointer;
    margin-top: 10px;

    li {
      display: flex;
      align-items: center;
      gap: 4px;
    }

    .mail,
    .number {
      color: white;
    }
  }

  .follow {
    margin-top: 15px;
    .icons {
      li {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .followhead {
    font-family: "Playfair Display", serif;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0.06em;
    color: #ffffff;
    margin-bottom: 8px;
  }

  .icons {
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .icons li {
    background: #f1ad55;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    cursor: pointer;
  }

  li::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 1px;
    background-color: white;
    transition: width 0.5s ease;
  }
  li:hover::after {
    width: 100%;
  }
`;

export const Copy = styled.p`
  margin-top: auto;
  background: black;
  height: 48px;
  color: white;
  width: 100%;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
