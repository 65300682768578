import React from "react";
import { SavedAddress } from "../Mob-styles/SavedAddress-Mob";
import { S3_ASSETS } from "../../../constants";

const addresses = [
  {
    name: "John Doe",
    address:
      "Plot No. 19-20, IDA, Vijay Nagar, Part 2, Part II, Indore, Madhya Pradesh 452010",
    phoneNumber: "+91 236 654 8974",
    default: true,
  },
  {
    name: "John Doe",
    address:
      "Plot No. 19-20, IDA, Vijay Nagar, Part 2, Part II, Indore, Madhya Pradesh 452010",
    phoneNumber: "+91 236 654 8974",
    default: false,
  },
  {
    name: "John Doe",
    address:
      "Plot No. 19-20, IDA, Vijay Nagar, Part 2, Part II, Indore, Madhya Pradesh 452010",
    phoneNumber: "+91 236 654 8974",
    default: false,
  },
];

const SavedAddressMob = () => {
  const handleIconClick = () => {
    console.log("Icon clicked!"); // Log message on icon click
  };
  const handleEdit = (index) => {
    // Implement edit logic here, e.g., open a modal or navigate to an edit page
    console.log(`Edit address at index ${index}`);
  };

  const handleRemove = (index) => {
    // Implement remove logic here
    console.log(`Remove address at index ${index}`);
  };

  const handleMakeDefault = (index) => {
    // Implement set default logic here
    console.log(`Set address at index ${index} as default`);
  };

  return (
    <SavedAddress>
      <h2>Saved Addresses</h2>
      <div className="saved-address">
        <div className="addresswrapper">
          <div className="icon-container" onClick={handleIconClick}>
            <div className="img_txt">
              <img
                src={`${S3_ASSETS}/Icons/addButton.svg`}
                alt="logo"
                className="profile_logo"
              />
              <span>Add Address</span>
            </div>
          </div>
          {addresses.map((address, index) => (
            <div
              key={index}
              className={`address-item ${
                address.default ? "default-address" : ""
              }`}
            >
              <div className="details-container">
                <p id="name">
                  {address.name}
                  <span className={address.default ? "default-label" : ""}>
                    {address.default && "    (Default)"}
                  </span>
                </p>
                <p className="address">{address.address}</p>
                <p className="phoneNumber"> {address.phoneNumber}</p>
                <div className="address-actions">
                  <span onClick={() => handleEdit(index)}>EDIT </span>
                  <span
                    className="other-span"
                    onClick={() => handleRemove(index)}
                  >
                    REMOVE
                  </span>
                  {!address.default && (
                    <span
                      className="other-span"
                      onClick={() => handleMakeDefault(index)}
                    >
                      MAKE DEFAULT
                    </span>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </SavedAddress>
  );
};

export default SavedAddressMob;
