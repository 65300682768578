import { CartPreviewContainer } from "./styles";
import { useSelector } from "react-redux";
import { RootState } from "../../store/types";
import { S3_ASSETS } from "../../constants";
import useOutsideClick from "../../helpers/hooks/useOutsideClick";
import { useEffect } from "react";
import { useNavigate } from "react-router";

export default function CartPreview({ setIsCartOpen }) {
  const navigate = useNavigate();
  const { items, totalPrice, totalQuantity } = useSelector(
    (state: RootState) => state.cart
  );
  const [ref, isElementClicked] = useOutsideClick<HTMLDivElement>();

  useEffect(() => {
    if (isElementClicked) setIsCartOpen({ val: false });
  }, [isElementClicked]);

  return (
    <CartPreviewContainer
      ref={ref}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <p className="title">Cart Preview</p>
      {items.length > 0 ? (
        <>
          <section className="items">
            {items.map((item, id) => {
              return (
                <section className="item" key={item.id}>
                  <img
                    src={item?.images[0].url}
                    alt="diamond"
                    className="img"
                  />
                  <section className="data">
                    <p className="title">{item.name}</p>
                    <p className="price">&#8377; {item.price}</p>
                  </section>
                </section>
              );
            })}
          </section>
          <section className="total">
            <p className="sub_title_text">Sub total ({totalQuantity})</p>
            <p className="price">&#8377; {totalPrice}</p>
          </section>
          <section className="buttons">
            <button
              className="cart"
              onClick={() => {
                navigate("/cart");
                setIsCartOpen(false);
              }}
            >
              VIEW CART
            </button>
            <button
              onClick={() => {
                navigate("/checkout");
                setIsCartOpen(false);
              }}
            >
              CHECKOUT
            </button>
          </section>
        </>
      ) : (
        <div className="empty_cart">
          <img
            className="empty_cart_img"
            src={`${S3_ASSETS}/Images/EmptyCart.png`}
          />
          <p>Your Shopping Cart is empty!</p>
          <button
            onClick={() => {
              navigate("/checkout");
              setIsCartOpen(false);
            }}
          >
            CHECKOUT
          </button>
        </div>
      )}
    </CartPreviewContainer>
  );
}
