import React, { useState } from "react";
import { SavedPayments } from "../Mob-styles/SavedPayments-Mob";
import { S3_ASSETS } from "../../../constants";

const SavedPaymentsMob = () => {
  const [cards, setCards] = useState([
    {
      bank: "Axis Bank Credit Card",
      cardNumber: "xxxx 1234",
      name: "John Doe",
    },
    {
      bank: "Axis Bank Credit Card",
      cardNumber: "xxxx 1234",
      name: "John Doe",
    },

    { bank: "UPI (State Bank of India)", upiID: "john.doe@upi" },
  ]);

  const handleEdit = (index) => {
    console.log(`Edit card at index ${index}`);
  };

  const handleRemove = (index) => {
    const updatedCards = [...cards];
    updatedCards.splice(index, 1);
    setCards(updatedCards);
  };

  const handleIconClick = () => {
    console.log("Icon clicked!");
  };

  const CardItem = ({ data, onEdit, onRemove }) => (
    <div className="card-item">
      {data.cardNumber ? (
        <>
          <div className="bankNameAndIcon">
            <div id="bank-name">{data.bank}</div>
            <img
              src={`${S3_ASSETS}/Icons/mastercard-icon.svg`}
              alt="logo"
              className="headeprofileEdit_logo"
            />
          </div>
          <div id="cardNumber">
            <p id="bankCardNumber">{data.cardNumber}</p>

            <p id="separator1"></p>
            <p id="username">{data.name}</p>
          </div>
          <div className="buttons">
            <button id="editButton" onClick={onEdit}>
              Edit
            </button>
            <h2 id="separator"></h2>
            <button id="removeButton" onClick={onRemove}>
              Remove
            </button>
          </div>
        </>
      ) : (
        <>
          <div id="bank-name">{data.bank}</div>
          <div id="cardNumber">{data.upiID}</div>
          <button id="removeButton" onClick={onRemove}>
            Remove
          </button>
        </>
      )}
    </div>
  );

  return (
    <SavedPayments>
      <h2 className="header">Saved Payments</h2>
      <div className="saved-payment">
        <div>
          <div className="innerWrapper">
            <div className="icon-container" onClick={handleIconClick}>
              <div className="img_txt">
                <img
                  src={`${S3_ASSETS}/Icons/addButton.svg`}
                  alt="logo"
                  className="profile_logo"
                />
                <span>Add New Payment</span>
              </div>
            </div>
            {cards.map((card, index) => (
              <CardItem
                key={index}
                data={card}
                onEdit={() => handleEdit(index)}
                onRemove={() => handleRemove(index)}
              />
            ))}
          </div>
        </div>
      </div>
    </SavedPayments>
  );
};

export default SavedPaymentsMob;
