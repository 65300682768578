import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { debounce, throttle } from "lodash";
import { RootState } from "../../store/types";
import { store } from "../../store/store";

export interface ApiCallerConfig extends AxiosRequestConfig {
  shouldRetry?: boolean;
  shouldThrottle?: boolean;
  shouldDebounce?: boolean;
  onSuccess?: (response: any) => void;
  onError?: (error: any) => void;
  onRetry?: (attempt: number) => void;
}

const authApis = ["cart"];

const cache = new Map<string, any>();

const setCache = (key: string, value: any) => {
  cache.set(key, value);
  setTimeout(() => cache.delete(key), 2 * 60 * 1000); // Cache expiration
};

const getFromCache = <T>(key: string): T | undefined => {
  return cache.get(key);
};

const createApiCall = (method: "get" | "post" | "patch") => {
  return async <T = any>(
    url: string,
    config: ApiCallerConfig = {},
    data?: any
  ): Promise<T> => {
    const cacheKey = `${method}:${url}`;
    const fromCache = getFromCache<T>(cacheKey);
    if (fromCache) return fromCache;

    const axiosConfig: AxiosRequestConfig = { ...config, url, method, data };
    if (authApis.some((txt) => url.includes(txt))) {
      const state: RootState = store.getState();
      if (state.auth?.accessToken)
        axiosConfig["headers"] = {
          Authorization: `Bearer ${state.auth.accessToken}`,
        };
    }
    const executeCall = async () => {
      try {
        const response = await axios(axiosConfig);
        setCache(cacheKey, response.data);
        config.onSuccess?.(response.data);
        return response.data as T;
      } catch (error) {
        config.onError?.(error);
        throw error;
      }
    };

    if (config.shouldRetry) {
      for (let attempt = 1; attempt <= 2; attempt++) {
        try {
          return await executeCall();
        } catch (error) {
          config.onRetry?.(attempt);
        }
      }
    }

    return executeCall();
  };
};

const debouncedApiCall = debounce(createApiCall, 300);
const throttledApiCall = throttle(createApiCall, 1000);

const ApiCaller = {
  get: <T = any>(url: string, config?: ApiCallerConfig): Promise<T> => {
    // @ts-ignore
    return (config?.shouldDebounce ? debouncedApiCall : throttledApiCall)(
      "get"
    )(url, config);
  },
  post: <T = any>(
    url: string,
    data?: any,
    config?: ApiCallerConfig
  ): Promise<T> => {
    // @ts-ignore
    return (config?.shouldDebounce ? debouncedApiCall : throttledApiCall)(
      "post"
    )(url, config, data);
  },
  patch: <T = any>(
    url: string,
    data?: any,
    config?: ApiCallerConfig
  ): Promise<T> => {
    // @ts-ignore
    return (config?.shouldDebounce ? debouncedApiCall : throttledApiCall)(
      "patch"
    )(url, config, data);
  },
};

export default ApiCaller;
