import { createAsyncThunk } from "@reduxjs/toolkit";

// You can define your action types as strings
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";

// This is your single async thunk
export const handleAuth = createAsyncThunk(
  "auth/handle",
  async (
    { actionType, username }: { actionType: string; username: string },
    thunkAPI
  ) => {
    switch (actionType) {
      case LOGIN:
        // Execute your async login logic here
        // For demonstration purposes, we're just returning a username
        return { username: username, actionType };

      case LOGOUT:
        // Execute your async logout logic here
        // For demonstration purposes, we're just nullifying the username
        return { username: null, actionType };

      default:
        throw new Error("Invalid action type");
    }
  }
);
