import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

interface BottomSheetProps {
  children: React.ReactNode;
  onClose?: () => void;
}

const StyledBottomSheet = styled.div`
  position: fixed;
  bottom: 85px;
  left: 0;
  right: 0;
  background-color: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 20px;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
  transform: translateY(100%);
  transition: transform 0.3s ease-in-out;

  &.visible {
    transform: translateY(0%);
  }
`;

const BottomSheet: React.FC<BottomSheetProps> = ({ children, onClose }) => {
  const [isVisible, setIsVisible] = useState(false);
  const bottomSheetRef = useRef<HTMLDivElement>(null);

  const handleClose = () => {
    setIsVisible(false);
    if (onClose) setTimeout(() => onClose(), 500);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      bottomSheetRef.current &&
      !bottomSheetRef.current.contains(event.target as Node)
    ) {
      handleClose();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    setTimeout(() => {
      setIsVisible(true);
    }, 300);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <StyledBottomSheet
      ref={bottomSheetRef}
      className={isVisible ? "visible" : ""}
    >
      {children}
    </StyledBottomSheet>
  );
};

export default BottomSheet;
