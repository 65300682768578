import { S3_ASSETS } from "../../constants";
export const headerOptions = [
  {
    name: "Best Sellers",
    url: "/plp",
  },
  {
    name: "New Arrivals",
    url: "/plp",
  },
  // {
  //   name: "Rings",
  //   url: "/plp",
  // },
  {
    name: "Diamonds",
    url: "/plp",
  },
  {
    name: "Gemstones",
    url: "/plp",
  },
  // {
  //   name: "Fine Jewellery",
  //   url: "/plp",
  // },
  {
    name: "Special Deals",
    url: "/plp",
  },
];

export const userOptions = [
  {
    name: "Account",
    url: "/login",
    icon: `${S3_ASSETS}/Icons/user.svg`,
    isCart: false,
  },
  {
    name: "Cart",
    url: "/cart",
    icon: `${S3_ASSETS}/Icons/shop_bag.svg`,
    isCart: true,
  },
];
