import styled from "styled-components";

export const ProfileMob = styled.div`
padding:30px 0;
background: var(--grey, #EEF3F5);
button {
    /* width: 460px; */
    width: 100%;
    height: 4rem;
    flex-shrink: 0;
    border-radius: 8px;
    background: #f1ad55;
    border: none;
    margin-bottom: 0px;
  }

  .update-profile-section button {
    margin-top: 20px;
  }

  label {
    color: #000;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  input {
    /* width: 460px; */
    height: 3.5rem;
    /* flex-shrink: 0; */
    border-radius: 8px;
    background: var(--Grey, #eef3f5);
    margin-top: 10px;
    color: #000;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    /* margin-top: 10px; */
    /* padding: 17px 77px 17px 13px; */
    border: none;
    padding:15px 16px;
  }

  .horizontal-rectangle {
    width: 1060px;
    height: 1px;
    flex-shrink: 0;
    border-radius: 8px;
    background: var(--Grey, #eef3f5);
    margin: 50px 0;
    transform: translateX(-50px);
  }

  .enterNewPassword {
    position: relative;
  }
  .eye-icon {
    position: absolute;
    top: 57%;
    left: 90%;
    cursor: pointer;
  }
  .change-password-button {
    margin-right: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    button {
      width: 100%;
      height: 4rem;
      border-radius: 8px;
      background: #f1ad55;
      border: none;
    }
  }

  .column {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .row{
    display:flex;
    flex-direction:column;
    gap:20px;

}
.header{
    color: #011D29;
font-family: Reckless Neue;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 100% */
margin:20px;
}
.change-password-section{
    margin-top:30px;
}
`;
export const EditWrapper = styled.div`
background: var(--white, #FFF);
.containerEdit{
    margin:16px auto;
    padding:20px;
    display:flex;
    flex-direction:column;
    gap:10px;
    aign-items:center;
    justify-content:center;
}
.icon-container{
    margin:40px 0;
    display:flex;
    align-items:center;
    justify-content:center;
}
.icons-wrap{
    width:fit-content;
    position:relative;
    .headeprofileEdit_logo{
        position: absolute;
    top: 5px;
    right:0;
    }
}

`;
export const ChangePassword = styled.div`
background: var(--white, #FFF);
.wrapChangePassword{
    margin:16px auto;
    padding:20px;
    display:flex;
    flex-direction:column;
    gap:30px;
}
`;
