import styled from "styled-components";
import { Z_INDEXES } from "../../constants";
import { useNavigate } from "react-router";

export const HeaderContainer = styled.div`
  width: 100%;
  height: 80px;
  /* max-width: 1600px; */
  background: linear-gradient(
    to right,
    #111c1f,
    #121e21,
    #122125,
    #11262d,
    #1d2724,
    #22251f
  );
  padding: 18px 50px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: fixed;
  z-index: ${Z_INDEXES.HEADER};
  top: 0;
  .header-navigation-options {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .categories {
      display: flex;
      justify-content: space-evenly;
    }
  }
  .header_img {
    width: 80px;
    margin-left: 12px;
    @media (min-width: 1800px) {
      margin-left: 48px;
      transform: scaleX(1.4);
    }
  }
  .header-links {
    position: relative;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 400;
    text-transform: uppercase;
    color: #fff;
    margin-left: 2%;
    padding-bottom: 2px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 1px;
      background-color: white;
      transition: width 0.5s ease;
    }
    &:hover::after {
      width: 100%;
    }
  }

  .header-user-options {
    /* min-width: 100px; */
    /* margin-left: auto; */
    width: fit-content;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    a {
      display: flex;
      .option-icon {
        width: 14px;
        height: 14px;
        margin-right: 2px;
      }
      .icon_n_text {
        display: flex;
        width: 100%;
      }
      .cart-count {
        position: absolute;
        bottom: 0px;
        left: 6px;
        color: white;
        padding: 1px 1px;
        border-radius: 20%;
        font-size: 8px;
      }
    }
  }

  @media screen and (min-width: 1100px) and (max-width: 1300px) {
    .header-navigation-options {
      margin-left: 5%;
      width: 100%;
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }

  @media screen and (min-width: 800px) and (max-width: 1100px) {
    .header-navigation-options {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 4px;
      margin-left: 10px;
    }

    .header_img {
      width: 60px;
      margin-left: -30px;
    }

    .header-links {
      text-decoration: none;
      font-size: 12px;
      font-weight: 500;
      text-transform: uppercase;
      color: #fff;
      margin-left: 10px;
    }
  }
`;
export const Section = styled.section`
  position: relative;
  cursor: pointer;
  .cart_sheet {
    position: absolute;
    top: 120%;
    left: calc(-420px + 100%);
    z-index: 7;
  }
  .option-icon {
    width: 14px;
    height: 14px;
    margin-right: 2px;
    &:last-child {
      width: 16px;
      height: 16px;
    }
  }
  .icon_n_text {
    display: flex;
    width: 100%;
  }
  .cart-count {
    position: absolute;
    bottom: 4px;
    left: 4px;
    color: white;
    padding: 1px 1px;
    padding-bottom: 0;
    border-radius: 20%;
    font-size: 8px;
  }
`;
