import React, { Suspense, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/types";
import { DEVICES } from "../../constants";
import Loader from "../../context/LoaderContext/Loader";
import { ProductProvider } from "../../context/ProductContext/ProductContext";
import { useLocation } from "react-router";
const Desktop = React.lazy(() => import("./Desktop"));
const Mobile = React.lazy(() => import("./Mobile"));
const Search = React.lazy(() => import("../../pages/Search"));
export const PLP_SEARCH_URL = "/plp/search";

export default function Listing() {
  const { device } = useSelector((state: RootState) => state.app);
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ProductProvider>
      {location.pathname === PLP_SEARCH_URL ? (
        <Suspense fallback={<Loader />}>
          <Search />
        </Suspense>
      ) : device === DEVICES.MOBILE ? (
        <Suspense fallback={<Loader />}>
          <Mobile />
        </Suspense>
      ) : (
        device === DEVICES.DESKTOP && (
          <Suspense fallback={<Loader />}>
            <Desktop />
          </Suspense>
        )
      )}
    </ProductProvider>
  );
}
