export function getCookie(name) {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    const [cookieName, cookieValue] = cookie.split("=");
    if (cookieName === name) {
      return decodeURIComponent(cookieValue);
    }
  }
  return null;
}

export function setCookie(
  name,
  value,
  minutesToExpire = 5,
  path = "/",
  domain = ""
) {
  const expirationDate = new Date();
  expirationDate.setTime(
    expirationDate.getTime() + minutesToExpire * 60 * 1000
  );

  let cookieString = name + "=" + encodeURIComponent(value);

  if (expirationDate) {
    cookieString += "; expires=" + expirationDate.toUTCString();
  }

  if (path) {
    cookieString += "; path=" + path;
  }

  if (domain) {
    cookieString += "; domain=" + domain;
  }

  document.cookie = cookieString;
}

export function updateCookie(
  name,
  newValue,
  minutesToExpire: number | null = 5,
  path = "/",
  domain = ""
) {
  const existingCookie = getCookie(name);
  if (existingCookie !== null) {
    if (minutesToExpire === null) {
      deleteCookie(name);
    } else {
      setCookie(name, newValue, minutesToExpire, path, domain);
    }
  } else {
    setCookie(name, newValue, minutesToExpire || 1, path, domain);
  }
}

export function deleteCookie(name) {
  document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}
