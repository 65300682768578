import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  position: fixed;
  bottom: 0;
  z-index: 12;
`;

export const Screen = styled.div`
  height: 30px;
  width: 100%;
  background-color: transparent;
`;

export const Nav = styled.nav`
  background: rgba(255, 255, 255, 0.05);
  display: flex;
  flex-flow: row nowrap;
  background: white;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  height: 80px;
  box-shadow: 0 8px 32px 8px rgba(0, 0, 0, 0.3);
`;

export const NavItem = styled.div<{ isHump: boolean }>`
  flex: 1;
  position: relative;
  cursor: pointer;
  height: 3.5rem;

  .hump {
    top: -24px;
    left: 0;
    right: 0;
    width: 100%;
    position: absolute;
    transform: scaleY(${(props) => (props.isHump ? 1 : 0)});
    transform-origin: bottom;
    transition: transform 500ms ease-out;
    fill: white;
  }

  .title {
    color: #bbb;
    position: absolute;
    left: 0;
    right: 0;
    bottom: ${(props) => (props.isHump ? "16px" : "-150%")};
    font-size: 12px;
    text-align: center;
    transition: bottom 500ms ease-out;
  }

  .icon {
    position: absolute;
    top: ${(props) => (props.isHump ? "-8px" : "14px")};
    left: 0;
    right: 0;
    margin: 0 auto;
    transition: top 500ms ease-out;
    fill: ${(props) => (props.isHump ? "rgba(255, 255, 255, 0.05)" : "#aaa")};
  }
`;
