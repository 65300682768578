import { useSelector } from "react-redux";
import { RootState } from "../../store/types";
import { DEVICES, hideFooterRoutes } from "../../constants";
import Footer from "./Footer";
import MobileFooter from "./MobileFooter";
import { useLocation } from "react-router";

export default function FooterWrapper() {
  const location = useLocation();
  const { device } = useSelector((state: RootState) => state.app);
  const shouldHide = hideFooterRoutes.includes(location.pathname);
  if (shouldHide) return null;
  return (
    <div style={location.pathname === "/plp" ? { marginTop: "200px" } : {}}>
      {device === DEVICES.DESKTOP ? <Footer /> : <MobileFooter />}
    </div>
  );
}
