import styled from "styled-components";

export const CartPreviewContainer = styled.div`
  width: 420px;
  max-height: 460px;
  padding: 24px;
  border-radius: 15px 0px 15px 15px;
  background: var(--white, #fff);
  box-shadow: 0px 8px 15px 10px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  & > .title {
    color: #011d29;
    font-family: "Playfair Display", serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    border-bottom: 2px solid rgba(238, 243, 245, 0.5);
  }

  .empty_cart {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background: var(--Grey, #eef3f5);
    padding: 16px;
    align-items: center;
    p {
      color: #011d29;
      font-size: 20px;
      font-family: "Playfair Display", serif;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-align: center;
      padding: 8px 0;
    }
    .empty_cart_img {
      width: 180px;
      aspect-ratio: 1;
      text-align: center;
    }
    button {
      padding: 16px 24px;
      border-radius: 4px;
      color: #fff;
      text-align: center;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: uppercase;
      background-color: #f1ad55;
      border: none;
    }
  }

  .items {
    display: flex;
    flex-direction: column;
    gap: 20px;
    /* max-height: 50%; */
    overflow-y: scroll;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    overflow-y: scroll; /* Add vertical scroll */
    &::-webkit-scrollbar {
      display: none;
    }
    .item {
      padding: 16px 0;
      display: flex;
      gap: 16px;
      height: fit-content;
      border-bottom: 2px solid rgba(238, 243, 245, 0.5);
      &:last-child {
        border: none;
      }
      .img {
        max-height: 128px;
        height: fit-content;
        aspect-ratio: 1;
        /* padding: 20px; */
        box-sizing: border-box;
        background-color: rgb(238, 243, 245, 0.5);
      }
      .data {
        display: flex;
        flex-direction: column;
        gap: 12px;
        p {
          color: #011d29;
        }
        .title {
          font-family: Inter;
          font-size: 18px;
          font-weight: 600;
          line-height: 28px;
          white-space: wrap;
        }
        .price {
          font-family: Inter;
          font-size: 24px;
          font-weight: 700;
          line-height: normal;
        }
      }
    }
  }
  .total {
    border-top: 2px solid rgba(238, 243, 245, 0.5);

    display: flex;
    justify-content: space-between;
    p {
      color: #011d29;
      font-family: Inter;
      &:last-child {
        text-align: right;
      }
    }
    .sub_title_text {
      font-size: 20px;
      font-weight: 500;
      line-height: 32px;
    }
    .price {
      font-size: 26px;
      font-weight: 700;
      line-height: normal;
    }
  }
  .buttons {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    button {
      padding: 16px 24px;
      border-radius: 4px;
      color: #fff;
      text-align: center;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: uppercase;
      background-color: #f1ad55;
      border: none;
      &.cart {
        background-color: #eef3f5;
        color: #f1ad55;
        border: 1px solid rgba(0, 0, 0, 0.5);
      }
    }
  }
`;
