export const S3_ASSETS =
  "https://eksolitaire-frontend-assets.s3.ap-southeast-2.amazonaws.com";

export const DEVICES = {
  MOBILE: "mobile",
  DESKTOP: "desktop",
};

export const BASE_COLOR1 = "rgb(8,26,31)";
export const Z_INDEXES = {
  HEADER: 6,
  HEADER_NAV: 7,
};

export const hideHeaderRoutes = ["/login", "/signup"];
export const hideFooterRoutes = [
  "/login",
  "/signup",
  "/checkout",
  "/checkout/address",
  "/checkout/paymentMethod",
  "/checkout/orderSuccessful",
];
export const BASE_URL = "https://preprod-backend.eksolitaire.com/v1";

export const TOKEN_COOKIE_NAME = "LOGIN_TOKEN";
