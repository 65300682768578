import React from "react";
import { MyWishList_Style } from "../Mob-styles/MyWishList_StyleMob";
import { S3_ASSETS } from "../../../constants";

const MyWishlist = () => {
  const [myWishlist, setMyWishlist] = React.useState([
    {
      WishOrderName: "1.01 Carat G SI1 Excellent Cut Round Diamond",
      WishOrderId: "123456",
      price: "₹ 1,30,033",
    },
    {
      WishOrderName: "2.01 Carat G SI1 Excellent Cut Round Diamond",
      WishOrderId: "789013",
      price: "₹ 1,30,033",
    },
    {
      WishOrderName: "3.01 Carat G SI1 Excellent Cut Round Diamond",
      WishOrderId: "1234567",
      price: "₹ 1,30,033",
    },
    {
      WishOrderName: "4.01 Carat G SI1 Excellent Cut Round Diamond",
      WishOrderId: "78901",
      price: "₹ 1,30,033",
    },
  ]);

  const handleRemoveWishOrder = (WishOrderId) => {
    setMyWishlist((prevWishlist) =>
      prevWishlist.filter((WishOrder) => WishOrder.WishOrderId !== WishOrderId)
    );
  };

  return (
    <MyWishList_Style>
      <h2 className="header">My Wishlist</h2>
      <div className="my-Wishlist">
        <div className="myWishList-wrapper">
          {myWishlist.map((WishOrder, index) => (
            <div key={index} className="myWishList-item">
              <div className="picAndId">
                <div className="icons">
                  <img
                    src={`${S3_ASSETS}/Icons/orders-diamond-icon.png`}
                    alt="logo"
                    className="orders-diamond-icon"
                  />
                </div>
                {/* <h4>{WishOrder.WishOrderId}</h4> */}
              </div>

              <div className="myWishlist-details">
                <h3 id="WishOrderName">{WishOrder.WishOrderName}</h3>
                <h3 id="wishOrderPrice">{WishOrder.price}</h3>
                <button
                  id="remove-button"
                  onClick={() => handleRemoveWishOrder(WishOrder.WishOrderId)}
                >
                  Remove
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </MyWishList_Style>
  );
};

export default MyWishlist;
