// MySearch.js

import React from "react";
import { MySearch_Style } from "../Mob-styles/MySearch_StyleMob";
import { S3_ASSETS } from "../../../constants";

const MySearchMob = () => {
  const [searchResults, setSearchResults] = React.useState([
    {
      title: "1.01 Carat G SI1 Excellent Cut Round Diamond",
      price: "₹ 1,30,033",
    },
    {
      title: "1.01 Carat G SI1 Excellent Cut Round Diamond",
      price: "₹ 1,30,033",
    },
    {
      title: "1.01 Carat G SI1 Excellent Cut Round Diamond",
      price: "₹ 1,30,033",
    },
    {
      title: "1.01 Carat G SI1 Excellent Cut Round Diamond",
      price: "₹ 1,30,033",
    },
  ]);

  const handleViewProduct = (selectedResult) => {
    console.log("View Product:", selectedResult);
  };

  const handleRemoveSearch = (selectedResult) => {
    console.log("hello");
    // setSearchResults((prevResults) =>
    //   prevResults.filter((result) => result !== selectedResult)
    // );
  };
  const handleClearAllSearch = () => {
    setSearchResults([]);
  };

  return (
    <MySearch_Style>
      <div className="mySearch-bar">
        <h2 className="header">My Search Results</h2>
        <button onClick={handleClearAllSearch}>Clear </button>
      </div>
      <div className="my-search">
        <div className="mySearch-wrapper">
          {searchResults.map((result, index) => (
            <div key={index} className="search-results-item">
              <div className="icons">
                <span>
                  <img
                    src={`${S3_ASSETS}/Icons/diamondBackground.png`}
                    alt="logo"
                    className="bg-search-diamond-icon"
                  />
                  <img
                    src={`${S3_ASSETS}/Icons/mySearchDiamondIcon.png`}
                    alt="logo"
                    className="search-diamond-icon"
                  />
                </span>
              </div>

              <div>
                <h3 id="title">{result.title}</h3>
                <h3 id="price">{result.price}</h3>

                <div className="action-button">
                  <button
                    id="view-button"
                    onClick={() => handleViewProduct(result)}
                  >
                    View Product
                  </button>

                  <p id="separator"></p>

                  <button
                    id="remove-button"
                    onClick={() => handleRemoveSearch(result)}
                  >
                    Remove
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </MySearch_Style>
  );
};

export default MySearchMob;
