import React from "react";
const Homepage = React.lazy(() => import("../pages/Homepage/HomeWrapper"));
const Product_Detail = React.lazy(() => import("../pages/Product_Detail"));
const Login = React.lazy(() => import("../pages/Login/Login"));
const Signup = React.lazy(() => import("../pages/Signup"));
const Listing = React.lazy(() => import("../pages/Listing"));

export const PublicRoutes = [
  {
    path: "/",
    element: Homepage,
  },

  {
    path: "/plp/*",
    element: Listing,
  },
  {
    path: "/pdp/:id",
    element: Product_Detail,
  },
  {
    path: "/login",
    element: Login,
  },
  {
    path: "/signup",
    element: Signup,
  },
  {
    path: "/plp",
    element: Listing,
  },
];
