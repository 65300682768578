import styled from "styled-components";

export const MyOffers = styled.div`
background: var(--grey, #EEF3F5);
.wrapTop{
  display:flex;
  gap:11px;
  align-items:center;
}
  .wrapper{
    h2 {
      color: #011D29;
      font-family: Reckless Neue;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 111.111% */
      padding:20px;
      padding-bottom:16px;
    }
  }
  .my-offers {
    /* width: 1060px; */
    /* height: 680px; */
    flex-shrink: 0;
    border-radius: 10px;
    background: var(--white, #fff);
    padding: 20px;
  }

  .diaImg {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: rgb(238 243 245 / 50%);
    width: 64.229px;
    height: 64.229px;
    img{
      width: 32px;
      height: 35px;
    }
  }

  .offer-item {
    display: flex;
    gap: 20px;
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--Dark-grey, #dbe0e2);
    background: var(--white, #fff);
    margin-bottom: 1.28rem;
    position: relative;
    padding: 16px 19px 17px 16px;
    flex-direction: column;
    align-items: flex-start;

    .text-column {
      width: fit-content;

      h3 {
        color: var(--blue, #00AAB7);
        font-family: Reckless Neue;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */
        margin-top: 4px;
        margin-bottom: 10px;
      }
    }

    .info-column {
      display: flex;
      justify-content: space-between;
      align-items: end;
      width: 100%;
      align-items: center;
    }
  }

  .info-column span {
    color: #011d29;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.5;
    margin-top: 4px;
  }

  .info-column button {
    color: var(--golden, #F1AD55);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    margin-top: 15px;
    background: none;
    border: none;
  }

   /* @media screen and (max-width: 1250px) {
    .info-column {
      width: 35% !important;
    }
  } */
  @media screen and (min-width: 1650px) {
    .text-column {
      width: 100% !important;
    }
  }



  @media screen and (min-width: 1024px) {
    .info-column {
      width: 38% !important;
    }

    .info-column span {
      font-size: 14px;
    }

    .info-column button {
      font-size: 16px;
    }
  } 

  @media screen and (min-width: 800px) {
    .diaImg img {
      padding: 1rem;
    }
    .text-column {
      width: unset;
    }
    .info-column{
      margin-left: 0rem !important;

      span{
        text-align: center;
      }
    }
  }

  /* 
  .offer-item > .text-column {

    margin-top: 19px;

  }

  .info-column {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    box-sizing: border-box;
    margin-top: 19px;
    margin-right: 31px;
  }

  h3 {
    color: var(--blue, #00aab7);

    font-family: Reckless Neue;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; 
    margin-bottom: 10px;
    width: auto;
  }
  h4 {
    width: 249.711px;
    height: 25.506px;
    flex-shrink: 0;
    color: #011d29;

    font-family: Reckless Neue;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; 
    margin-bottom: 51px;
  }
  #offer-desc {
    color: #011D29;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }

  #offer-valid {
    color: #011d29;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.5;
    margin: 0;
  }

  h5 {
    color: var(--golden, #f1ad55);
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
  } */
`;
