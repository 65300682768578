import styled from "styled-components";

export const MyWishList_Style = styled.div`
  background: var(--grey, #eef3f5);
  .header {
    color: #011d29;
    font-family: Reckless Neue;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    padding: 20px;
    padding-bottom: 16px;
  }
  .my-Wishlist {
    flex-shrink: 0;
    border-radius: 10px;
    background: var(--white, #fff);
    padding: 20px;
  }

  .myWishList-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .myWishList-item {
      display: flex;
      border-radius: 8px;
      border: 1px solid var(--Dark-grey, #dbe0e2);
      background: var(--white, #fff);
      position: relative;
      padding: 12px;
      margin-bottom: 20px;
    }
  }

  .picAndId {
    margin-right: 11px;
    width: 101px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .icons {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 6.125rem;
    height: 6.125rem;
    border-radius: 0.625rem;
    /* opacity: 0.5; */
    background: var(--Grey, #eef3f5);
    /* background: url(<path-to-image>),
      lightgray -13.543px 0px / 128.511% 107.692% no-repeat;

    mix-blend-mode: difference; */
    img {
      padding: 1rem;
    }
  }

  .picAndId h4 {
    color: var(--Black, #000);
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    opacity: 0.8;
  }

  .myWishlist-details {
    display: flex;
    flex-direction: column;
    align-items: end;
    margin-top: 4px;
  }

  .myWishlist-details #WishOrderName {
    /* width: 315.281px; */
    /* height: 50.076px; */
    /* flex-shrink: 0; */
    color: #011d29;
    font-family: Reckless Neue;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 11px;
    margin-top: 30px;
  }

  .myWishlist-details #wishOrderPrice {
    color: #011d29;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 26px;
  }

  #remove-button {
    color: var(--Black, #000);
    text-align: right;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    opacity: 0.5;
  }

  button {
    background: none;
    border: none;
  }

  @media screen and (min-width: 800px) and (max-width: 1024px) {
    .myWishList-item {
      width: 100% !important;
    }

    #WishOrderName {
      margin-top: 2rem;
    }
  }

  @media screen and (min-width: 1025px) and (max-width: 1150px) {
    .myWishList-item {
      gap: 20px;
    }

    .myWishlist-details {
      text-align: center;
    }
  }
`;
