import AppRouter from "./routes/AppRouter";
import { BrowserRouter } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateWidth } from "./store/slices/appSlice";
import { debounce } from "lodash";
import LoaderProvider from "./context/LoaderContext/LoaderContext";
import BottomNav from "./components/BottomNav";

function App() {
  const dispatch = useDispatch();
  const onResize = debounce(() => {
    dispatch(updateWidth(window.innerWidth));
  }, 100);
  useEffect(() => {
    window.addEventListener("resize", onResize);
  }, []);
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <LoaderProvider>
          <AppRouter />
        </LoaderProvider>
        <BottomNav />
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
