import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { getAllProducts } from "../../api/productApis";
import {
  ProductProviderProps,
  ProductState,
  FilterState,
} from "./ProductContext.types";
import { usePageLoader } from "../LoaderContext/LoaderContext";
import { useLocation } from "react-router";
import { PLP_SEARCH_URL } from "../../pages/Listing/Listing";

const ProductContext = createContext<ProductState | undefined>(undefined);

const defaultFilters = {
  price: null,
  caratWeight: null,
  stoneLength: null,
  stoneWidth: null,
  color: null,
  stoneType: "All",
  cutGrade: "All",
  fluorescenceIntensity: "All",
  clarity: "All",
  certification: "All",
  shape: "All",
};

export const ProductProvider: React.FC<ProductProviderProps> = ({
  children,
}: ProductProviderProps) => {
  const loader = usePageLoader();
  const location = useLocation();
  const [data, setData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedFilters, setSelectedFilters] =
    useState<FilterState>(defaultFilters);
  const [sortBy, setSortBy] = useState<string>("price:asc");
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [limit, setLimit] = useState<number>(21);
  const dependencyArray = useMemo(() => {
    return location.pathname == PLP_SEARCH_URL
      ? [selectedFilters]
      : [selectedFilters, sortBy, page, limit];
  }, [location.pathname, selectedFilters]);
  useEffect(() => {
    const fetchData = async () => {
      loader.setShowLoader(true);
      try {
        const filters = selectedFilters;
        const filterParams = Object.keys(filters)
          .map((key) => {
            if (!filters[key]) return "";
            if (
              key === "stoneType" ||
              key === "shape" ||
              key === "cutGrade" ||
              key === "fluorescenceIntensity" ||
              key === "clarity" ||
              key === "certification"
            ) {
              if (filters[key]?.toLocaleLowerCase() === "all") return "";
              return `${key}=${filters[key]}`;
            } else {
              return Object.entries(filters[key])
                .map(
                  ([filterKey, filterValue]) =>
                    `${key}[${filterKey}]=${filterValue}`
                )
                .join("&");
            }
          })
          .filter((params) => params !== "")
          .join("&");

        const queryString = filterParams
          ? `?${filterParams}&sortBy=${sortBy}&limit=${limit}&page=${page}`
          : `?sortBy=${sortBy}&limit=${limit}&page=${page}`;

        const { results, totalPages } = await getAllProducts(queryString);
        setData(results);
        setTotalPages(totalPages);
        setIsLoading(false);
        setError(null);
        loader.setShowLoader(false);
      } catch (error) {
        setError("Failed To Fetch");
        setIsLoading(false);
        loader.setShowLoader(false);
      }
    };
    fetchData();
  }, dependencyArray);

  const contextValue: ProductState = {
    data,
    isLoading,
    error,
    selectedFilters,
    setSelectedFilters,
    setSortBy,
    setPage,
    setLimit,
    totalPages,
    page,
    setTotalPages,
  };

  return (
    <ProductContext.Provider value={contextValue}>
      {children}
    </ProductContext.Provider>
  );
};

export const useProductContext = (): ProductState => {
  const context = useContext(ProductContext);

  if (!context) {
    throw new Error("useProductContext must be used within a ProductProvider");
  }

  return context;
};
