import React from "react";

const Account = React.lazy(() => import("../pages/Account"));
const Order = React.lazy(
  () => import("../pages/PlaceOrder/PlaceOrder_desktop/Order")
);
const CartDelivery2 = React.lazy(() => import("../pages/Delivery_Address/"));
const PaymentMethod = React.lazy(() => import("../pages/paymentMethod"));
const PlaceOrder = React.lazy(() => import("../pages/PlaceOrder/PlaceOrder"));
const Cart = React.lazy(() => import("../pages/Cart"));
const Wishlist = React.lazy(() => import("../pages/wishlist/Wishlist"));
const Mobprofile = React.lazy(
  () => import("../pages/Account/MobileSection/SideBarMob")
);

export const PrivateRoutes = [
  {
    path: "/checkout",
    element: CartDelivery2,
  },
  {
    path: "/checkout/paymentMethod",
    element: PaymentMethod,
  },
  {
    path: "/checkout/orderSuccessful",
    element: PlaceOrder,
  },
  {
    path: "/cart",
    element: Cart,
  },
  {
    path: "/account",
    element: Account,
  },
  {
    path: "/order",
    element: Order,
  },
  {
    path: "/wishlist",
    element: Wishlist,
  },
  {
    path: "/accountmob/:id",
    element: Mobprofile,
  },
];
