import React, { useEffect, useState } from "react";
import { Nav, NavItem, Screen, Wrapper } from "./styles";
import { DEVICES, S3_ASSETS, TOKEN_COOKIE_NAME } from "../../constants";
import { useLocation, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { RootState } from "../../store/types";
import { PLP_SEARCH_URL } from "../../pages/Listing/Listing";
import BottomSheet from "../BottomSheet";
// const Account = React.lazy(
//   () => import("../../pages/Account/MobileSection/SideBarMob")
// ); // dont remove this
import Account from "../../pages/Account/MobileSection/SideBarMob";
import { getCookie } from "../../helpers/utils/Cookies";

const BottomNav: React.FC = () => {
  const location = useLocation();
  const { device } = useSelector((state: RootState) => state.app);
  const navigate = useNavigate();
  const [isSelected, setIsSelected] = useState(
    location.pathname == PLP_SEARCH_URL ? 1 : 0
  );
  const [bottomSheetOpen, setIsBottomSheetOpen] = useState(false);
  useEffect(() => {
    if (!bottomSheetOpen) {
      if (location.pathname == PLP_SEARCH_URL) setIsSelected(1);
      else if (location.pathname === "/") setIsSelected(0);
      else setIsSelected(3);
    }
  }, [location.pathname, bottomSheetOpen]);
  useEffect(() => {
    setIsBottomSheetOpen(false);
  }, [location.pathname]);
  if (device === DEVICES.DESKTOP) return null;
  return (
    <Wrapper>
      <Screen />
      <Nav>
        {navItems.map((item, index) => (
          <NavItem
            key={index}
            isHump={index === 1}
            onClick={() => {
              setIsSelected(index);
              if (item.url) navigate(item.url);
              if (item.BottomSheetComp) {
                if (getCookie(TOKEN_COOKIE_NAME)) setIsBottomSheetOpen(true);
                else navigate("/login");
              }
            }}
          >
            <svg
              className="hump"
              viewBox="0 0 100 25"
              xmlns="http://www.w3.org/2000/svg"
              width="100"
              height="25"
            >
              <path d="M0 25h100c-20 0-25-25-50-25s-30 25-50 25z" />
            </svg>
            {item.icon && (
              <span className="icon">
                <img
                  src={
                    item.iconselected && isSelected === index
                      ? item.iconselected
                      : item.icon
                  }
                />
              </span>
            )}
            {!!item.BottomSheetComp && bottomSheetOpen && (
              <BottomSheet onClose={() => setIsBottomSheetOpen(false)}>
                {item.BottomSheetComp}
              </BottomSheet>
            )}
          </NavItem>
        ))}
      </Nav>
    </Wrapper>
  );
};

const navItems = [
  {
    icon: `${S3_ASSETS}/Icons/homeunselected.svg`,
    iconselected: `${S3_ASSETS}/Icons/homeselected.svg`,
    url: "/",
  },
  {
    icon: `${S3_ASSETS}/Icons/navsearchicon.svg`,
    url: PLP_SEARCH_URL,
  },
  {
    icon: `${S3_ASSETS}/Icons/accountunselected.svg`,
    iconselected: `${S3_ASSETS}/Icons/accountselected.svg`,
    BottomSheetComp: <Account />,
  },
];

export default BottomNav;
