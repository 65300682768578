import { footerOptions } from "./config";
import { footerOptions2 } from "./config";
import { Foot } from "./styles";
import { Copy } from "./styles";
import { S3_ASSETS } from "../../constants";

const Footer = () => {
  return (
    <Foot>
      <div className="maindiv">
        <div className="div1">
          <img
            src={`${S3_ASSETS}/Logos/ekSol_logo.png`}
            alt=""
            className="FImg"
          />

          <p className="div1Para">
            Elegate your elegance with our <br />
            exquisite diamonds , offerings timeless <br />
            brilliance and unparalleled <br />
            craftmanship for your most cherished <br />
            moments .
          </p>
        </div>

        <div className="div2">
          <h2 className="div2head">QUICK LINKS</h2>

          <div className="lists2">
            {footerOptions.map((name, index) => {
              return <li key={index}>{name.Option}</li>;
            })}
          </div>
        </div>

        <div className="div3">
          <h2 className="div3head">COMPANY</h2>

          <div className="lists3">
            {footerOptions2.map((name, index) => {
              return <li key={index}>{name.Option}</li>;
            })}
          </div>
        </div>

        <div className="div4">
          <h2 className="div4head">CONTACT</h2>

          <div className="lists4">
            <li>
              {" "}
              <img
                src={`${S3_ASSETS}/images_temp/Call.png`}
                className="call"
                alt=""
              />{" "}
              <span className="number">+91 123 123 1234</span>{" "}
            </li>
            <li>
              {" "}
              <img
                src={`${S3_ASSETS}/images_temp/Email.png`}
                className="email"
                alt=""
              />{" "}
              <span className="mail">support@prddiamonds.com</span>
            </li>
          </div>

          <div className="follow">
            <h2 className="followhead">FOLLOW US ON</h2>

            <div className="icons">
              <li>
                {" "}
                <img src={`${S3_ASSETS}/images_temp/Fb.png`} alt="" />{" "}
              </li>
              <li>
                {" "}
                <img src={`${S3_ASSETS}/images_temp/Cross.png`} alt="" />{" "}
              </li>
              <li>
                {" "}
                <img src={`${S3_ASSETS}/images_temp/Yt.png`} alt="" />{" "}
              </li>
              <li>
                {" "}
                <img src={`${S3_ASSETS}/images_temp/Google.png`} alt="" />{" "}
              </li>
              <li>
                {" "}
                <img src={`${S3_ASSETS}/images_temp/Camera.png`} alt="" />{" "}
              </li>
            </div>
          </div>
        </div>
      </div>
      <Copy>Copyright 2023. All Rights Reserved. PRD Diamonds.</Copy>
    </Foot>
  );
};

export default Footer;
