import styled from "styled-components";

export const SavedAddress = styled.div`
background: var(--grey, #EEF3F5);
  .saved-address {
    /* width: 1060px; */
    /* height: 878px; */
    /* flex-shrink: 0; */
    border-radius: 10px;
    background: var(--white, #fff);
    gap: 20px;
    padding: 20px;
    background: var(--white, #fff);
  }

  h2 {
    border-radius: 10px;
    color: #011d29;
    font-family: Reckless Neue;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    padding:20px;
    padding-bottom:0;
    margin: 16px 0;
  }

  .icon-container {
    width: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 2.5rem;
    margin-bottom: 20px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 2px dashed var(--Dark-grey, #dbe0e2);
  }

  .img_txt {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 52px 0;
  }

  /* .icon-container img {
    margin-top: 108px;
    margin-left: 203px;
  } */

  .icon-container span {
    color: var(--Black, #000);
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    opacity: 0.5;
  }
  .addresswrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background: var(--white, #FFF);
  }

  .address-item {
    /* width: 460px; */
    width: 45%;
    margin-right: 2.5rem;
    margin-bottom: 20px;
    /* height: 316px; */
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid var(--Dark-grey, #dbe0e2);
    background: var(--white, #fff);
    /* margin-bottom: 40px; */
  }

  .details-container {
    padding: 20px;
  }

  #name {
    color: #011d29;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .default-label {
    color: var(--Black, #000);
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    opacity: 0.5;
  }
  .address {
    /* width: 399.13px; */
    /* width: 50%; */
    /* height: 90px; */
    flex-shrink: 0;
    color: #011d29;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    margin-top: 11px;
    opacity: 0.6;
  }

  .phoneNumber {
    /* width: 334px; */
    color: #000;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 21px;
  }

  .address-actions {
    margin-top: 51px;
  }

  .address-actions span {
    position: relative;
    padding-right: 30px;
    cursor: pointer;
  }

  .address-actions span:not(:last-child)::after {
    content: "";
    position: absolute;
    width: 1.5px;
    height: 14.49px;
    right: 15px;
    top: 50%;
    opacity: 0.3;
    background: #000;
    transform: translateY(-50%);
    margin-left: 150px;
  }

  .address-actions span:first-child {
    color: var(--golden, #f1ad55);
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
  }

  .address-actions .other-span {
    /* width: 153.693px; */
    /* height: 25.506px; */
    color: var(--Black, #000);
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    opacity: 0.5;
  }

  @media screen and (max-width: 1500px) {
    .address-item {
      margin-right: 0rem;
    }

    .icon-container {
      margin-right: 0rem;
    }
  }

  @media screen and (min-width: 980px) and (max-width: 1099px) {
    .phoneNumber {
      font-size: unset;
    }

    .address {
      font-size: unset;
    }
  }

  @media screen and (max-width: 980px) {
    .address-item,
    .icon-container {
      width: 100%;
    }

    .addresswrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
`;
