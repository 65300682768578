import { BASE_URL } from "../constants";
import ApiCaller from "../helpers/utils/ApiCaller";

export const getCart = async () => {
  try {
    const response = await ApiCaller.get(`${BASE_URL}/cart`, {
      shouldThrottle: true,
    });
    return response;
  } catch (error) {
    console.error("Fetching cart failed:", error);
    return { items: [] };
  }
};

export const addToCartApi = async (productId) => {
  try {
    const response = await ApiCaller.patch(
      `${BASE_URL}/cart/add-product/${productId}`,
      {},
      {
        shouldThrottle: true,
      }
    );
    return response;
  } catch (error) {
    console.error("adding to cart failed:", error);
    return {};
  }
};

export const removeFromCartApi = async (productId) => {
  try {
    const response = await ApiCaller.patch(
      `${BASE_URL}/cart/remove-product/${productId}`,
      {},
      {
        shouldThrottle: true,
      }
    );
    return response;
  } catch (error) {
    console.error("removing from cart failed:", error);
    throw error;
  }
};
