import styled from "styled-components";

export const SlideBarMob = styled.div`
  background: var(--grey, #eef3f5);
  .header {
    h2 {
      color: #011d29;
      font-family: Reckless Neue;
      padding: 20px;
      padding-bottom: 16px;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 111.111% */
    }
  }
  .wrap-1 {
    display: flex;
    p {
      line-height: 24px;
    }
    img {
      margin: 0;
      margin-right: 8px;
    }
  }
  .sidebar {
    border-radius: 10px;
    background-color: #ffffff;
    padding-left: 8px;
    padding-right: 8px;
    display: flex;
    flex-direction: column;
  }
  .menu-item {
    padding: 12px 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--grey, #eef3f5);
    background: #fff;
  }
  .wrap-2 {
    display: flex;
    width: fit-content;
  }
`;
