// NotificationPage.js

import React from "react";
import { Notification_style } from "../Mob-styles/Notification_styleMob";
import { S3_ASSETS } from "../../../constants";

const NotificationPage = () => {
  const [notifications, setNotifications] = React.useState([
    {
      description:
        "No min. order value! Avail Free Shipping on order value of Min.₹60000! Shop Now >",
      validTill: "Valid till Nov’ 20 2023",
    },
    {
      description:
        "No min. order value! Avail Free Shipping on order value of Min.₹60000! Shop Now >",
      validTill: "Valid till Nov’ 20 2023",
    },
  ]);

  const handleClearAllNotifications = () => {
    setNotifications([]);
  };

  const handleViewNotification = (selectedNotification) => {
    console.log("notification", selectedNotification);
  };

  return (
    <Notification_style>
      <div className="notification-bar">
        <h2>Notification</h2>
        <span id="clearButton" onClick={handleClearAllNotifications}>
          CLEAR
        </span>
      </div>
      <div className="notification-page">
        {notifications.map((notification, index) => (
          <div key={index} className="notification-item">
            <div className="wraptop">
              <div className="diaImg">
                <img
                  src={`${S3_ASSETS}/Icons/myOfffersDiamondIcon.svg`}
                  alt="logo"
                  className="orders-diamond-icon"
                />
              </div>

              <div className="text-column">
                <p id="notification-desc">{notification.description}</p>
              </div>
            </div>
            <div className="wrapBottom">
              <p id="notification-valid">{notification.validTill}</p>
              <button
                id="viewDetails-button"
                onClick={() => handleViewNotification(notification)}
              >
                VIEW DETAILS
              </button>
            </div>
          </div>
        ))}
      </div>
    </Notification_style>
  );
};

export default NotificationPage;
