export const footerOptions = [
    {
      Option: "DIAMOND GUIDE",
    },
    {
      Option: "JEWELLERY GUIDE",
    },
    {
      Option: "GEMSTONE GUIDE",
    },
    {
      Option: "GOLD RATE",
    },
    {
      Option: "DIGITAL GOLD",
    },
    {
      Option: "OLD GOLD EXCHANGE",
    },
  ];
  
  
  export const footerOptions2 = [
      {
        Option: "OUR STORY",
      },
      {
        Option: "PRESS",
      },
      {
        Option: "BLOGS",
      },
      {
        Option: "CAREERS",
      },
      {
        Option: "RETURN POLICY",
      },
      {
        Option: "ORDER STATUS",
      },
    ];