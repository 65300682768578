import styled from "styled-components";

export const Notification_style = styled.div`
background: var(--grey, #EEF3F5);
.wraptop{
  display:flex;
  gap:11px;
  align-items:center;
}
.wrapBottom{
  display: flex;
    align-items: center;
    justify-content:space-between;
}
.notification-bar {
  display: flex;
  padding: 20px;
  padding-bottom: 16px;
  h2 {
    color: #011D29;
    font-family: Reckless Neue;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 111.111% */
  }
}
  .notification-page {
    /* width: 1060px; */
    /* height: 680px; */
    flex-shrink: 0;
    border-radius: 10px;
    background: var(--white, #fff);
    padding: 20px;
  }

  #clearButton {
    color: var(--golden, #F1AD55);
    text-align: right;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    cursor:pointer;
  }
  .notification-item {
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 20px;
    border-radius: 8px;
    border: 1px solid var(--Dark-grey, #dbe0e2);
    background: var(--white, #fff);
    padding: 16px 19px 17px 16px;
    margin-bottom: 20px;
    flex-direction:column;
  }
   
  .diaImg {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.625rem;
    background: #f4f4f4;
    width: 4.01431rem;
    height: 4.01431rem;
    img{
      padding: 1rem;
    }
  }

  /* #image {
    width: 64.229px;
    height: 64.229px;
    flex-shrink: 0;
    border-radius: 10px;
    opacity: 0.5;
    background: var(--Grey, #eef3f5);
    margin-right: 11px;
  }

  #image .bg-search-diamond-icon {
    position: relative;
    /* z-index: 0; */
    /* width: 64.229px;
    height: 64.229px;
    flex-shrink: 0;
    border-radius: 10px;
    opacity: 0.5;
    background: var(--Grey, #eef3f5);
  } */

  #image .orders-diamond-icon {
    position: absolute;
    /* z-index: 1; */
    top: 0;
    left: 0;
    margin-top: 30px;
    margin-left: 32px;
    width: 32px;
    height: 35px;
    flex-shrink: 0;
  } */

  .text-column {
    width: 75%;
  }

  #viewDetails-button {
    text-align: end;
    width: 24%;
  }

  #notification-desc {
    color: #011d29;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 9px;
    margin-bottom: 10px;
  }

  #notification-valid {
    color: #011D29;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.5;
    width: fit-content;
  }

  #viewDetails-button {
    color: var(--golden, #F1AD55);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    width: auto;
  }

  button {
    background: none;
    border: none;
  }

  @media screen and (max-width: 1024px){
    #viewDetails-button{
      text-align: center;
    }
  }
`;
