import { useState } from "react";
import { MobileHeader } from "./Styles";
import { headerOptions } from "./Config";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/types";
import { S3_ASSETS } from "../../../constants";
import { Link, useNavigate } from "react-router-dom";

const MHeader = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const { totalQuantity } = useSelector((state: RootState) => state.cart);
  const { isLoggedIn } = useSelector((state: RootState) => state.auth);

  const handleClick = () => {
    setShow(!show);
  };

  return (
    <MobileHeader>
      <div className="MHead">
        <div className="Mheader">
          <div className="header_menu"></div>

          <div className="header_logo">
            <Link to="/">
              <img
                src={`${S3_ASSETS}/Logos/ekSol_logo.png`}
                alt=""
                className="header_img_mob"
              />
            </Link>
          </div>
        </div>

        <div className="Micons">
          <Link to={isLoggedIn ? "/accountmob/0" : "/login"}>
            <img
              className="header-icon"
              src={`${S3_ASSETS}/Icons/user.svg`}
              alt=""
            />
          </Link>
          <div className="line"></div>
          <div className="cart-icon" onClick={() => navigate("/cart")}>
            <img
              className="header-icon"
              src={`${S3_ASSETS}/Icons/shop_bag.svg`}
              alt=""
            />
            <span className="cart-count">{totalQuantity || 0}</span>
          </div>
        </div>
      </div>
    </MobileHeader>
  );
};

export default MHeader;
