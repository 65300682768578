import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CartState } from "../types";
import { addToCart, removeFromCart } from "../thunks/cartThunk";

const initialState: CartState = {
  items: [],
  totalQuantity: 0,
  totalPrice: 0,
};

function calculatePrice(cart) {
  let price = 0;
  cart.forEach((element) => {
    price += parseInt(element.price);
  });
  return parseFloat(`${price}`);
}

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    updateCart: (
      state,
      action: PayloadAction<{
        items: Record<string, any>[];
      }>
    ) => {
      const modifiedItems = action.payload.items.map((item) => item?.product);
      state.items = modifiedItems;
      state.totalQuantity = action.payload.items.length;
      state.totalPrice = calculatePrice(modifiedItems).toLocaleString();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addToCart.fulfilled, (state, action) => {
      // Handle success: update the state with the response if needed
      // For example, you can push the response to the items array
      // @ts-ignore
      if (action.payload.id) {
        const newItems = [...state.items, action.payload];
        state.items = newItems;
        state.totalQuantity = state.totalQuantity + 1;
        state.totalPrice = calculatePrice(newItems);
      } else {
        window.history.replaceState({}, "", "/login");

        // Manually dispatch a popstate event to inform the browser about the URL change
        const event = new Event("popstate");
        window.dispatchEvent(event);
      }
    });

    builder.addCase(removeFromCart.fulfilled, (state, action) => {
      // Handle success: remove the item with the matching ID from the state
      const newItems = state.items.filter(
        (item, i) => item.id !== action.payload.id
      );
      state.items = newItems;
      state.totalQuantity = state.totalQuantity - 1;
      state.totalPrice = calculatePrice(newItems);
    });
  },
});

export const { updateCart } = cartSlice.actions;

export default cartSlice.reducer;
