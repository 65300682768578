import React from "react";
import { MyOrders_Style } from "../Mob-styles/MyOrders_StyleMob";
import { S3_ASSETS } from "../../../constants";

const orders = [
  {
    orderName: "1.01 Carat G SI1 Excellent Cut Round Diamond",
    orderId: "ID - 12346",
    price: "₹ 1,30,033",
  },
  {
    orderName: "1.01 Carat G SI1 Excellent Cut Round Diamond",
    orderId: "ID - 12346",
    price: "₹ 1,30,033",
  },
  {
    orderName: "1.01 Carat G SI1 Excellent Cut Round Diamond",
    orderId: "ID - 12346",
    price: "₹ 1,30,033",
  },
  {
    orderName: "1.01 Carat G SI1 Excellent Cut Round Diamond",
    orderId: "ID - 12346",
    price: "₹ 1,30,033",
  },
];

const MyOrdersMob = () => {
  const handleViewDetails = (order) => {
    console.log("Order Details:", order);
  };
  return (
    <MyOrders_Style>
      <h2 className="header">My Orders</h2>
      <div className="my-orders">
        <div className="my-orders-wrapper">
          {orders.map((order, index) => (
            <div key={index} className="order-item">
              <div className="top">
                <div className="picAndId">
                  <div id="image">
                    <img
                      src={`${S3_ASSETS}/Icons/orders-diamond-icon.png`}
                      alt="logo"
                      className="orders-diamond-icon"
                    />
                  </div>
                </div>

                <div className="order-details">
                  <h3 id="orderName">{order.orderName}</h3>
                  <h3 id="orderPrice">{order.price}</h3>
                </div>
              </div>
              <div className="bottom">
                <h4>{order.orderId}</h4>
                <button
                  id="view-details-button"
                  onClick={() => handleViewDetails(order)}
                >
                  View Details
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </MyOrders_Style>
  );
};

export default MyOrdersMob;
