import React from "react";
import { MyOffers } from "../Mob-styles/MyOffers_StyleMob";
import { S3_ASSETS } from "../../../constants";

const MyOffersMob = () => {
  const myOffers = [
    {
      title: "Flat 1200 of on diamonds",
      description:
        "Get extra ₹ 12000 of on 1 item(s) (Price inclusive of Cashback/coupons)",
      validTill: "Valid till Nov’ 20 2023",
    },
    {
      title: "Flat 1200 of on diamonds",
      description:
        "Get extra ₹ 12000 of on 1 item(s) (Price inclusive of Cashback/coupons)",
      validTill: "Valid till Nov’ 20 2023",
    },
    {
      title: "Flat 1200 of on diamonds",
      description:
        "Get extra ₹ 12000 of on 1 item(s) (Price inclusive of Cashback/coupons)",
      validTill: "Valid till Nov’ 20 2023",
    },
    {
      title: "Flat 1200 of on diamonds",
      description:
        "Get extra ₹ 12000 of on 1 item(s) (Price inclusive of Cashback/coupons)",
      validTill: "Valid till Nov’ 20 2023",
    },
  ];

  const handleViewDetails = (offer) => {
    console.log(" the offer is ", offer);
  };

  return (
    <MyOffers>
      <div className="wrapper">
        <h2>My Offers</h2>
        <div className="my-offers">
          {myOffers.map((offer, index) => (
            <div key={index} className="offer-item">
              <div className="wrapTop">
                <div className="diaImg">
                  <img
                    src={`${S3_ASSETS}/Icons/myOfffersDiamondIcon.svg`}
                    alt="logo"
                    className="orders-diamond-icon"
                  />
                </div>

                <div className="text-column">
                  <h3>{offer.title}</h3>
                  <p id="offer-desc">{offer.description}</p>
                </div>
              </div>
              <div className="info-column">
                <span id="offer-valid">{offer.validTill}</span>
                <button onClick={() => handleViewDetails(offer)}>
                  VIEW DETAILS
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </MyOffers>
  );
};

export default MyOffersMob;
