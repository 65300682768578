import styled from "styled-components";

export const MyOrders_Style = styled.div`
background: var(--grey, #EEF3F5);
.top{
  display: flex;
  align-items: center;
  gap: 11px;
}
.bottom{
  align-items: center;
  display:flex;
  justify-content:space-between;
}
.header {
  color: #011D29;
  font-family: Reckless Neue;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 111.111% */
  padding:20px;
  padding-botttom:16px;
}
  .my-orders {
    /* width: 1060px; */
    /* height: 680px; */
    flex-shrink: 0;
    border-radius: 10px;
    background: var(--white, #fff);
    padding: 20px;
  }

  .my-orders-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .order-item {
    display: flex;
    gap: 11px;
s    border-radius: 8px;
    border: 1px solid var(--Dark-grey, #dbe0e2);
    background: var(--white, #fff);
    padding: 20px 20px 21px 16px;
    margin-bottom: 20px;
    position: relative;
    flex-direction:column;
  }

  .picAndId {
    margin-right: 11px;
    width: 101px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  .order-details {
    display: flex;
    flex-direction: column;
    align-items: end;
    margin-top: 4px;
  }

  #image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 98px;
    height: 98px;
    border-radius: 0.625rem;
    /* opacity: 0.5; */
    background: var(--Grey, #eef3f5);
  }

  .bottom h4 {
    color: var(--Black, #000);
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    opacity: 0.8;
  }

  .order-details #orderName {
    color: #011D29;
    font-family: Reckless Neue;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    margin-bottom:11px;
  }

  .order-details #orderPrice {
    color: #011D29;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  #view-details-button {
    color: var(--Black, #000);
    text-align: right;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    opacity: 0.5;
  }

  button {
    background: none;
    border: none;
  }

  @media screen and (min-width: 800px) and (max-width: 1024px) {
    .order-item {
      width: 100% !important;
    }
  }

  @media screen and (min-width: 1025px) and (max-width: 1150px) {
    .picAndId {
      justify-content: flex-start;
      height: fit-content;
    }

    .order-item {
      gap: 20px;
    }
    #view-details-button {
      text-align: center;
    }
  }
  /* 
  span {
    width: 100%;
    height: 25.506px;
    flex-shrink: 0;
    color: #011d29;
    font-family: Reckless Neue;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; 
    margin-bottom: 70px;
  }
  .order-item {
    width: 460px;
    height: 173px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid var(--Dark-grey, #dbe0e2);
    background: var(--white, #fff);
    margin-bottom: 40px;

    h2 {
      width: 315.281px;
      height: 50.076px;
      flex-shrink: 0;
      color: #011d29;
      font-family: Reckless Neue;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; 
      margin-bottom: 0px;
    }

    h3 {
      color: #011d29;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-top: 11px;
    }
    h4 {
      color: var(--Black, #000);
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: uppercase;
      opacity: 0.8;
    }
    #view-details-button {
      color: var(--Black, #000);
      text-align: right;
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: uppercase;
      opacity: 0.5;
      margin-left: 302px;
      margin-top: 26px;
      margin-right: 20px;
    }
  } */
`;
