import React, { SetStateAction, useContext, useState, Dispatch } from "react";
import Loader from "./Loader";

const initailState: {
  showLoader: boolean;
  setShowLoader: Dispatch<SetStateAction<boolean>>;
} = {
  showLoader: false,
  //@ts-ignore
  setShowLoader: (str: boolean) => {},
};
export const LoaderContext = React.createContext(initailState);

export const usePageLoader = () => {
  return useContext(LoaderContext);
};
const Provider = ({ children }) => {
  const [showLoader, setShowLoader] = useState(false);
  return (
    <LoaderContext.Provider value={{ showLoader, setShowLoader }}>
      {showLoader && <Loader />}
      {children}
    </LoaderContext.Provider>
  );
};
export default Provider;
