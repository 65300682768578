import styled from "styled-components";
import { Z_INDEXES } from "../../../constants";

export const MobileHeader = styled.div`
  position: fixed;
  z-index: ${Z_INDEXES.HEADER};
  top: 0;
  .nav-container {
    position: relative;
  }
  .header_img_mob {
    width: 70px;
  }
  .MHead {
    background: linear-gradient(
      to right,
      #111c1f,
      #121e21,
      #122125,
      #11262d,
      #1d2724,
      #2c2e2a
    );
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 100%;
    height: 70px;
    padding: 12px;
  }

  .Mheader {
    display: flex;
    flex-direction: row;
    width: 12rem;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    p {
      margin: 0;
    }
  }
  .burger {
    color: white;
    width: 25px;
  }
  .header_menu {
    padding: 6px;
    border-radius: 8px;
    opacity: 0.8;
    width: fit-content;
  }
  .header_logo {
    margin-top: 4px;
  }

  .Micons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: min-content;
    gap: 14px;
    .header-icon {
      width: 28px;
      height: 28px;
    }
    .cart-icon {
      position: relative;
    }
    .cart-count {
      position: absolute;
      z-index: 2;
      color: white;
      border-radius: 50%;
      top: 65%;
      left: 50%;
      width: fit-content;
      font-size: 10px;
      padding: 4px;
      transform: translate(-50%, -50%);
    }
  }
  .line {
    border-left: 2px solid #564d4d;
    height: 20px;
    position: relative;
    top: 4px;
  }

  .mobile_nav {
    position: absolute;
    left: 0;
    top: 0;
    padding: 10px 24px;
    display: flex;
    background: black;
    transition: 0.5s ease;
    flex-direction: column;
    z-index: ${Z_INDEXES.HEADER_NAV};
    background-color: black;
    ul {
      margin-left: 0;
    }
    .cancelBtn {
      color: goldenrod;
      text-align: right;
      padding: 10px;
      margin-left: auto;
      width: fit-content;
      border: 1px solid transparent;
      &:hover {
        border: 1px solid goldenrod;
        opacity: 0.8;
        cursor: pointer;
      }
    }
  }

  .mobile_nav ul li {
    color: white;
    list-style-type: none;
    cursor: pointer;
    text-decoration: none;
    font-family: cursive;
    border: 1px solid transparent;
    opacity: 0.8;
    box-shadow: 0;
    &:hover {
      opacity: 1;
      box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
    }
  }

  .mobile_nav ul li a {
    text-decoration: none;
    color: white;
    text-align: left;
  }

  .nav-items-mobileView .active {
    color: black;
    background-color: yellow;
    border-radius: 90px;
    padding: 5px;
    width: 100%;
  }

  .overlay__close {
    font-size: 27px;
    color: goldenrod;
    cursor: pointer;

    position: absolute;
    top: 20px;
    right: 20px;
  }

  @media screen and (min-width: 400px) and (max-width: 500px) {
  }

  @media screen and (min-width: 500px) and (max-width: 600px) {
  }
  @media screen and (min-width: 600px) and (max-width: 700px) {
  }
  @media screen and (min-width: 700px) and (max-width: 800px) {
    .MHead {
      width: 800px;
    }
  }
`;
