import React, { useState } from "react";
import {
  ProfileMob,
  EditWrapper,
  ChangePassword,
} from "../Mob-styles/MobEditprofile";
import { DEVICES, S3_ASSETS } from "../../../constants";

const EditProfileMob = () => {
  const [profileData, setProfileData] = useState({
    firstName: "",
    lastName: "",
    emailAddress: "",
  });

  const [passwordData, setPasswordData] = useState({
    currentPassword: "",
    newPassword: "",
  });

  const [showNewPassword, setShowNewPassword] = useState(false);

  const toggleShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };
  const handleProfileUpdate = () => {
    console.log("Updating Profile:", profileData);
  };

  const handleChangePassword = () => {
    console.log("Changing Password:", passwordData);
  };

  return (
    <ProfileMob>
      <div className="container">
        <div className="update-profile-section">
          <h2 className="header">Edit Profile</h2>
          <EditWrapper>
            <div className="containerEdit">
              <div className="icon-container">
                <div className="icons-wrap">
                  <img
                    src={`${S3_ASSETS}/Icons/profile.svg`}
                    alt="logo"
                    className="profile_logo"
                  />
                  <img
                    src={`${S3_ASSETS}/Icons/profile edit.svg`}
                    alt="logo"
                    className="headeprofileEdit_logo"
                  />
                </div>
              </div>

              <div className="row">
                <div className="column">
                  <label>First Name</label>
                  <input
                    type="text"
                    value={profileData.firstName}
                    onChange={(e) =>
                      setProfileData({
                        ...profileData,
                        firstName: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="column">
                  <label>Last Name</label>
                  <input
                    type="text"
                    value={profileData.lastName}
                    onChange={(e) =>
                      setProfileData({
                        ...profileData,
                        lastName: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="column">
                  <label>Email Address</label>
                  <input
                    type="email"
                    value={profileData.emailAddress}
                    onChange={(e) =>
                      setProfileData({
                        ...profileData,
                        emailAddress: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="column">
                  <button onClick={handleProfileUpdate}>Update Profile</button>
                </div>
              </div>
            </div>
          </EditWrapper>
        </div>
        <div className="change-password-section">
          <h2 className="header">Change Password</h2>
          <ChangePassword>
            <div className="wrapChangePassword">
              <div className="row">
                <div className="column ">
                  <label>Enter Current Password</label>
                  <input
                    type="password"
                    value={passwordData.currentPassword}
                    onChange={(e) =>
                      setPasswordData({
                        ...passwordData,
                        currentPassword: e.target.value,
                      })
                    }
                  />
                </div>

                <div className="column enterNewPassword">
                  <label>Enter New Password</label>
                  <input
                    type={showNewPassword ? "text" : "password"}
                    value={passwordData.newPassword}
                    onChange={(e) =>
                      setPasswordData({
                        ...passwordData,
                        newPassword: e.target.value,
                      })
                    }
                  />
                  <img
                    src={`${S3_ASSETS}/Icons/eyeIcon.svg`}
                    alt="show-password"
                    className="eye-icon"
                    onClick={toggleShowNewPassword}
                  />
                </div>
              </div>
              <div className="change-password-button">
                <button onClick={handleChangePassword}>Change Password</button>
              </div>
            </div>
          </ChangePassword>
        </div>
      </div>
    </ProfileMob>
  );
};

export default EditProfileMob;
