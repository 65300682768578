import styled from "styled-components";

export const PandS = styled.div`
background: var(--grey, #EEF3F5);
.header{
  color: #011D29;
font-family: Reckless Neue;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 111.111% */
margin-bottom:15px;
padding:20px;
padding-bottom:0px;
}
.privacy-and-security {
    padding: 20px;
    flex-shrink: 0;
    border-radius: 10px;
    background: var(--white, #fff);
  }

  .toggle-switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  .toggle-switch .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;
  }

  .toggle-switch {
    /* width: 51.154px; */
    height: 20px;
    border: none;
    border-radius: 10px;
    /* flex-shrink: 0; */
  }

  .toggle-switch .slider:before {
    position: absolute;
    content: "";
    height: 25px;
    width: 25px;
    left: 4px;
    bottom: -2px;
    background-color: black;
    transition: 0.4s;
    border-radius: 50%;
  }

  .toggle-switch.enabled .slider {
    background-color: #4caf50;
  }

  .toggle-switch.enabled .slider:before {
    transform: translateX(26px);
  }

  .privacy-content h3 {
    color: #011D29;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .two-factor-Auth {
    display: flex;
    justify-content: space-between;
  }

  .privacy-content .paragraph {
    color: #011D29;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; /* 166.667% */
    opacity: 0.6;
    margin-top: 20px;
  }

  .privacy-content .paragraph-span {
    opacity: 0.6;
    color: var(--blue, #00aab7);
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
  }

  .submit-button {
    /* width: 460px; */
    /* height: 64px; */
    /* flex-shrink: 0; */
    border-radius: 8px;
    background: #f1ad55;
    margin-top: 72px;
    border: none;
    margin-bottom:50px;
    padding: 1rem 4rem;
    width:100%;
  }

  #submit-button-text {
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
  }
`;
