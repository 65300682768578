import { createAsyncThunk } from "@reduxjs/toolkit";
import { addToCartApi, removeFromCartApi } from "../../api/cartApis";
import isEmpty from "lodash/isEmpty";

export const addToCart = createAsyncThunk(
  "cart/addToCart",
  async (payload: any) => {
    try {
      const response = await addToCartApi(payload.id);
      // You can process the response here if needed
      if (!isEmpty(response)) return payload;

      return {};
    } catch (error) {
      // Handle errors if necessary
      throw error;
    }
  }
);

// Create an async thunk for removing an item from the cart
export const removeFromCart = createAsyncThunk(
  "cart/removeFromCart",
  async (payload: any) => {
    try {
      await removeFromCartApi(payload.id);
      return payload; // Return the item ID for removal
    } catch (error) {
      // Handle errors if necessary
      throw error;
    }
  }
);
