// PrivacyAndSecurity.js

import React, { useState } from "react";
import { PandS } from "../Mob-styles/P&SStyleMob";

const PrivacyAndSecurity = () => {
  const [isAuthenticationEnabled, setIsAuthenticationEnabled] = useState(false);

  const handleToggleAuthentication = () => {
    // Implement logic to handle toggle authentication state
    setIsAuthenticationEnabled(!isAuthenticationEnabled);
  };
  const handleSetupAuthentication = () => {
    console.log(
      `Authentication is now ${
        isAuthenticationEnabled ? "disabled" : "enabled"
      }.`
    );
  };

  return (
    <PandS>
      <div className="privacyContainer">
        <h2 className="header">Privacy & Security</h2>
        <div className="privacy-and-security">
          <div className="privacy-content">
            <div className="two-factor-Auth">
              <h3>Two-factor Authentication </h3>
              <button
                className={`toggle-switch ${
                  isAuthenticationEnabled ? "enabled" : ""
                }`}
                onClick={handleToggleAuthentication}
              >
                <span className="slider round"></span>
              </button>
            </div>
            <p className="paragraph">
              Add an extra layer of security to your online control panel by
              enabling two-factor authentication - an entirely optional feature
              of your account. if enabled you will be required to enter a
              variable code generated by authenticator app each time you log in.{" "}
              <span className="paragraph-span"> Read our guide.</span>
            </p>
            <button
              className="submit-button"
              onClick={handleSetupAuthentication}
            >
              <p id="submit-button-text"> SETUP AUTHENTICATION</p>
            </button>
          </div>
        </div>
      </div>
    </PandS>
  );
};

export default PrivacyAndSecurity;
