import { useSelector } from "react-redux";
import { RootState } from "../../store/types";
import { DEVICES, hideHeaderRoutes } from "../../constants";
import Header from "./Header";
import MHeader from "./MobileHeader/MHeader";
import { useLocation } from "react-router";

export default function HeaderWrapper() {
  const location = useLocation();
  const shouldHide = hideHeaderRoutes.includes(location.pathname);
  const { device } = useSelector((state: RootState) => state.app);

  if (shouldHide) return null;
  if (device === DEVICES.DESKTOP) return <Header />;
  return <MHeader />;
}
