import { S3_ASSETS, TOKEN_COOKIE_NAME } from "../../../constants";
import { SlideBarMob } from "../Mob-styles/sidebarMob";
import EditProfileMob from "./EditProfile-Mob";
import SavedAddressMob from "./SavedAddress-Mob";
import SavedPaymentsMob from "./SavedPaymentMob";
import PrivacyAndSecurity from "./PrivacyAndSecurityMob";
import MyOrdersMob from "./MyOrderMob";
import MyWishlist from "./MyWishlistMob";
import MyOffersMob from "./MyOffersMob";
import NotificationPage from "./NotificationMob";
import MySearchMob from "./MySearchMob";
import { useNavigate, useParams } from "react-router";
import styled from "styled-components";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/types";
import { deleteCookie } from "../../../helpers/utils/Cookies";
import { logout } from "../../../store/slices/authSlice";

export const Header = styled.div`
  padding-bottom: 16px;
  padding-left: 16px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  .usr_img {
    width: 40px;
    height: 40px;
    background-color: #eef3f5;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .infor {
    display: flex;
    flex-direction: column;
    gap: 2px;
    & > .title-head {
      color: #011d29;
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      padding-left: 32px;
    }
    & > .sub {
      color: var(--dark-blue, #011d29);
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding-left: 32px;
    }
  }
`;

const config = [
  {
    text: "Edit Profile",
    url: "sideBarEditProfile",
    id: 1,
  },
  {
    text: "Saved Address",
    url: "sideBarSavedAddress",
    id: 2,
  },
  {
    text: "Saved Payments",
    url: "sideBarSavedPayment",
    id: 3,
  },
  {
    text: "Privacy & Security",
    url: "sideBarPrivacyAndSecurity",
    id: 4,
  },
  {
    text: "My Orders",
    url: "sideBarMyOrders",
    id: 5,
  },
  {
    text: "My Wishlist",
    url: "sideBarMyWishlist",
    id: 6,
  },
  {
    text: "My Offers",
    url: "sideBarMyOffers",
    id: 7,
  },
  {
    text: "Notification",
    url: "sideBarNotification",
    id: 8,
  },
  {
    text: "My Searches",
    url: "sideBarMySearches",
    id: 9,
  },
  {
    text: "Logout",
    url: "logouticon",
    id: 10,
  },
];

const SideBarMob = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const email = useSelector((state: RootState) => state.auth.email);
  let name = "";
  if (email) {
    const idx = email.indexOf("@");
    name = email.slice(0, idx);
  }
  const navigate = useNavigate();
  const navigateRoute = (id) => {
    if (id < 10) navigate("/accountmob/" + id);
    else {
      deleteCookie(TOKEN_COOKIE_NAME);
      dispatch(logout());
      navigate("/");
    }
  };
  const tabIndex = Number(params.id) || 0;
  return (
    <>
      {tabIndex === 0 && (
        <SlideBarMob>
          <Header>
            <section className="usr_img">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="67"
                height="67"
                viewBox="0 0 67 67"
                fill="none"
              >
                <circle cx="33.8984" cy="33.8984" r="33.1016" fill="#EEF3F5" />
                <path
                  d="M43.8669 38.0248C42.3599 36.5118 40.5277 35.3623 38.5098 34.664C39.9429 33.6795 41.0235 32.2621 41.5934 30.6193C42.1634 28.9764 42.1929 27.1942 41.6776 25.5333C41.1623 23.8725 40.1293 22.4201 38.7295 21.3886C37.3298 20.3572 35.6369 19.8008 33.8984 19.8008C32.1598 19.8008 30.4669 20.3572 29.0672 21.3886C27.6674 22.4201 26.6344 23.8725 26.1191 25.5333C25.6038 27.1942 25.6333 28.9764 26.2033 30.6193C26.7732 32.2621 27.8538 33.6795 29.2869 34.664C26.5177 35.6263 24.1166 37.4268 22.4168 39.8158C20.717 42.2048 19.8027 45.0637 19.8008 47.996H22.0031C21.9851 46.4221 22.2794 44.8602 22.8691 43.4009C23.4587 41.9416 24.332 40.6138 25.4383 39.4945C26.5446 38.3751 27.8619 37.4865 29.314 36.8799C30.766 36.2734 32.324 35.9611 33.8976 35.9611C35.4712 35.9611 37.0291 36.2734 38.4812 36.8799C39.9333 37.4865 41.2506 38.3751 42.3569 39.4945C43.4632 40.6138 44.3364 41.9416 44.9261 43.4009C45.5157 44.8602 45.8101 46.4221 45.792 47.996H47.9959C48.001 46.1434 47.6387 44.3083 46.9299 42.5967C46.2212 40.8852 45.1801 39.3312 43.8669 38.0248ZM33.8984 33.8947C32.7211 33.8947 31.5703 33.5456 30.5914 32.8913C29.6126 32.2371 28.8497 31.3073 28.3991 30.2193C27.9486 29.1314 27.8307 27.9343 28.0604 26.7793C28.2901 25.6244 28.857 24.5635 29.6894 23.7309C30.5219 22.8982 31.5825 22.3312 32.7371 22.1014C33.8917 21.8717 35.0886 21.9896 36.1762 22.4402C37.2638 22.8909 38.1935 23.654 38.8475 24.6331C39.5016 25.6122 39.8507 26.7633 39.8507 27.9409C39.8507 29.5199 39.2235 31.0343 38.1073 32.1509C36.991 33.2675 35.477 33.8947 33.8984 33.8947Z"
                  fill="#011D29"
                />
                <path
                  d="M43.2969 47.9961H24.5C24.5 45.5035 25.4901 43.1129 27.2527 41.3503C29.0152 39.5878 31.4058 38.5977 33.8984 38.5977C36.3911 38.5977 38.7817 39.5878 40.5442 41.3503C42.3068 43.1129 43.2969 45.5035 43.2969 47.9961Z"
                  fill="#F1AD55"
                />
              </svg>
            </section>
            <section className="infor">
              <p className="title-head">{name}</p>
              <p className="sub">+91 9999999999</p>
            </section>
          </Header>
          <div className="sidebar">
            {config.map((item) => (
              <div
                key={item.id}
                className={`menu-item wrapper-list`}
                onClick={() => navigateRoute(item.id)}
              >
                <div className="wrap-1">
                  <img
                    src={`${S3_ASSETS}/Icons/${item.url}.svg`}
                    alt="icon"
                    className="menu-item-icon"
                  />
                  <p> {item.text}</p>
                </div>
              </div>
            ))}
          </div>
        </SlideBarMob>
      )}
      {tabIndex === 1 && <EditProfileMob />}
      {tabIndex === 2 && <SavedAddressMob />}
      {tabIndex === 3 && <SavedPaymentsMob />}
      {tabIndex === 4 && <PrivacyAndSecurity />}
      {tabIndex === 5 && <MyOrdersMob />}
      {tabIndex === 6 && <MyWishlist />}
      {tabIndex === 7 && <MyOffersMob />}
      {tabIndex === 8 && <NotificationPage />}
      {tabIndex === 9 && <MySearchMob />}
    </>
  );
};

export default SideBarMob;
