import { useLayoutEffect } from "react";
import { TOKEN_COOKIE_NAME } from "../constants";
import { getCookie } from "../helpers/utils/Cookies";
import { Navigate } from "react-router";

export default function AuthRoute({
  children,
}: {
  children?: React.ReactNode;
}) {
  const isLoggedIn = getCookie(TOKEN_COOKIE_NAME);
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  if (!children || !isLoggedIn) {
    return <Navigate to="/login" />;
  }
  return <>{children}</>;
}
