import styled from "styled-components";

export const MySearch_Style = styled.div`
background: var(--grey, #EEF3F5);
.header{
  color: #011D29;
  font-family: Reckless Neue;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 111.111% */
}
  .my-search {
    border-radius: 10px;
    background: var(--white, #fff);
    padding: 20px;
  }

  .mySearch-bar {
    display: flex;
    justify-content: space-between;
    padding:20px;
    padding-bottom:20px;

    button {
      color: var(--golden, #F1AD55);
      text-align: right;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: uppercase;
    }
  }
  .mySearch-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .search-results-item {
      display: flex;
      gap: 11px;
      /* width: 460px; */
      /* height: 173px; */
      /* flex-shrink: 0; */
      border-radius: 8px;
      border: 1px solid var(--Dark-grey, #dbe0e2);
      background: var(--white, #fff);
      padding: 20px 20px 21px 16px;
      margin-bottom: 20px;
      position: relative;
      /* display: inline-block;  */

      .search-diamond-icon,
      .bg-search-diamond-icon {
        top: 0;
        left: 0;
      }
      .search-diamond-icon {
        position: absolute;
        margin-top: 39px;
        margin-left: 26px;
      }

      .bg-search-diamond-icon {
        position: relative;
        width: 98px;
        height: 98px;
        flex-shrink: 0;
        border-radius: 10px;
        opacity: 0.5;
        background: var(--Grey, #eef3f5);
      }

      .icons {
        width: 98px;
        height: fit-content;
      }

      #title {
        /* width: 315.281px;
        height: 50.076px;
        flex-shrink: 0; */
        color: #011d29;
        font-family: Reckless Neue;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        margin-top: 4px;
        margin-bottom: 11px;
      }

      #price {
        color: #011d29;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 25px;
      }

      .action-button {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        #separator {
          width: 1.5px;
          height: 14.49px;
          opacity: 0.3;
          background: #000;
          transform: translateY(20%);
          margin: 0 15px;
        }

        #view-button {
          color: var(--golden, #f1ad55);
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-transform: uppercase;
        }

        #remove-button {
          color: var(--Black, #000);
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-transform: uppercase;
          opacity: 0.5;
        }
      }
    }
  }

  button {
    background: none;
    border: none;
  }

  @media screen and (min-width: 800px) and (max-width: 1024px) {
    .search-results-item {
      width: 100% !important;
    }

    #separator {
      width: 1.5px !important;
      opacity: 0.3 !important;
      background: #000 !important;
      margin: 10px 15px !important;
      transform: unset !important;
    }
  }

  @media screen and (min-width: 1025px) and (max-width: 1439px) {
    .search-results-item .action-button{
      margin-left: -4rem !important;
    }
  }
  /* 


 

    h2 {
      height: 25.506px;
      flex-shrink: 0;
      color: #011d29;
      font-family: Reckless Neue;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; 
    }
  }
  .search-results-item {
    width: 460px;
    height: 173px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid var(--Dark-grey, #dbe0e2);
    background: var(--white, #fff);
    padding: 20px 20px 21px 16px;
    margin-bottom: 40px;

    h2 {
      width: 315.281px;
      height: 50.076px;
      flex-shrink: 0;
      color: #011d29;
      font-family: Reckless Neue;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; 
      margin-bottom: 0px;
    }

    h3 {
      color: #011d29;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-top: 11px;
      margin-bottom: 25px;
    }

    #separator {
      width: 1.5px;
      height: 14.49px;
      opacity: 0.3;
      background: #000;
      transform: translateY(30%);
      margin: 0 15px; 
    }
    .action-button {
      display: flex;
      justify-content: flex-end;
    }

 
  } */
`;
