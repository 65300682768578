import { Link } from "react-router-dom";
import { S3_ASSETS } from "../../constants";
import { headerOptions, userOptions } from "./config";
import { HeaderContainer, Section } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/types";
import React, { useEffect, useState } from "react";
import CartPreview from "../CartPreview";
import throttle from "lodash/throttle";
import { getCart } from "../../api/cartApis";
import { updateCart } from "../../store/slices/cartSlice";

function Header() {
  const [isCartOpen, setIsCartOpen] = useState(false);
  const { totalQuantity, totalPrice } = useSelector(
    (state: RootState) => state.cart
  );
  const dispatch = useDispatch();

  const handleCartOpen = throttle(({ e, val }) => {
    if (e) {
      e.stopPropagation();
    }
    setIsCartOpen(val || !isCartOpen);
  }, 1000);

  useEffect(() => {
    async function cartApi() {
      const res = await getCart();
      dispatch(updateCart(res || { items: [] }));
    }
    cartApi();
  }, []);

  return (
    <HeaderContainer>
      <Link to="/">
        <img
          src={`${S3_ASSETS}/Logos/ekSol_logo.png`}
          alt="Logo"
          className="header_img"
        />
      </Link>
      <div className="header-navigation-options">
        <div className="categories">
          {headerOptions.map((option) => {
            return (
              <Link className="header-links" to={option.url}>
                {option.name}
              </Link>
            );
          })}
        </div>
        <div className="header-user-options">
          {userOptions.map((option) => {
            const Element = option.isCart ? Section : Link;
            return (
              <Element
                to={option.url}
                className="header-links"
                onClick={(e) => handleCartOpen({ e, val: !isCartOpen })}
              >
                <section className="icon_n_text">
                  {" "}
                  <img className="option-icon" src={option.icon} />
                </section>
                {option.isCart ? (
                  <>
                    <span className="cart-count">{totalQuantity}</span>
                    <span>{totalPrice}&nbsp;INR</span>
                  </>
                ) : (
                  <span>&nbsp;Account</span>
                )}
                {option.isCart && isCartOpen && (
                  <section className="cart_sheet">
                    <CartPreview setIsCartOpen={handleCartOpen} />
                  </section>
                )}
              </Element>
            );
          })}
        </div>
      </div>
    </HeaderContainer>
  );
}
export default React.memo(Header);
