import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DEVICES } from "../../constants";
import { AppState } from "../types";

const initialState: AppState = {
  device: window.innerWidth > 800 ? DEVICES.DESKTOP : DEVICES.MOBILE,
  deviceWidth: -1,
};

function calculateDevice(width: number) {
  if (width >= 800) {
    return DEVICES.DESKTOP;
  }
  return DEVICES.MOBILE;
}

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    updateWidth: (state, action: PayloadAction<number>) => {
      state.deviceWidth = action.payload;
      state.device = calculateDevice(action.payload);
    },
  },
});
export const { updateWidth } = appSlice.actions;

export default appSlice.reducer;
