import styled from "styled-components";
import { BASE_COLOR1, Z_INDEXES } from "../../constants";

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    255,
    255,
    255,
    0.9
  ); /* Semi-transparent white background */
  z-index: ${Z_INDEXES.HEADER + 5};
  .loader {
    border: 5px solid rgba(0, 0, 0, 0.1); /* Light grey border */
    border-top: 5px solid ${BASE_COLOR1}; /* Blue border for the top side */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
  }

  /* Add animation */
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
